<template>
  <common-dialog
    :visible.sync="visible"
    title="订单详情"
    type="alert"
    :confirm="confirm"
    :cancel="cancel"
    :close="cancel"
    
    size="60%"
  >
    <detail
      class="detail"
      :planBillId="planBillId"
      ref="detail"
      :type="type"
      :disabled="type === 'detail'"
      :visible.sync="visible"
      @getTableData="getTableData"
    ></detail>

  </common-dialog>
</template>

<script>
import CommonDialog from '@/components/Common/CommonDialog.vue'
import Detail from '@/components/order/OrderDetail.vue'
export default {
  components: {
    CommonDialog,
    Detail
  },
  data () {
    return {
      visible: false,
      type: 'add',
      planBillId: '',
    }
  },
  methods: {
    confirm() {

    },
    cancel() {
      this.$refs.detail.resetForm()
    },
    getTableData() {
      this.$emit('getTableData')
    }
  }
}
</script>

<style scoped lang="scss">
.detail {
  width: 100%;
}
</style>
