import request from '@/utils/http.js'

/**
 * 数据分析
 * @param {object} data 
 * @returns 
 */
export const queryPlanBillAnalysis = data => {
  return request({
    url: '/biz/plan/oms/queryPlanBillAnalysis',
    method: 'post',
    data
  })
}
/**
 * 订单管理
 * @param {object} data 
 * @returns 
 */
export const queryPlanBillList = data => {
  return request({
    url: '/biz/plan/oms/queryPlanBillList',
    method: 'post',
    data
  })
}
/**
 * 获取订单详情
 * @param {object} data 
 * @returns 
 */
export const getPlanBill = data => {
  return request({
    url: '/biz/plan/oms/getPlanBill',
    method: 'get',
    params: data
  })
}
/**
 * 下单
 * @param {object} data 
 * @returns 
 */
export const addPlanBill = data => {
  return request({
    url: '/biz/plan/oms/addPlanBill',
    method: 'post',
    data
  })
}
/**
 * 订单修改
 * @param {object} data 
 * @returns 
 */
export const updatePlanBill = data => {
  return request({
    url: '/biz/plan/oms/updatePlanBill',
    method: 'post',
    data
  })
}
/**
 * 取消订单
 * @param {object} data 
 * @returns 
 */
export const cancelPlanBill = data => {
  return request({
    url: '/biz/plan/oms/cancelPlanBill',
    method: 'get',
    params: data
  })
}
/**
 * 发货联系人电话
 * @param {object} data 
 * @returns 
 */
export const getConsignerContacts = data => {
  return request({
    url: '/base/cnor/queryConsignerContacts',
    method: 'post',
    data
  })
}
/**
 * 发货地址
 * @param {object} data 
 * @returns 
 */
export const getConsignerAddrs = data => {
  return request({
    url: '/base/cnor/queryConsignerAddrs',
    method: 'post',
    data
  })
}
/**
 * 收货单位列表
 * @param {object} data 
 * @returns 
 */
export const getBaseReceiverList = data => {
  return request({
    url: '/base/rcvr/omsQueryBaseReceiverList',
    method: 'get',
    params: data
  })
}
/**
 * 收货人列表
 * @param {object} data 
 * @returns 
 */
export const getBaseReceiverContactList = data => {
  return request({
    url: '/base/rcvr/omsQueryBaseReceiverContactList',
    method: 'get',
    params: data
  })
}
/**
 * 收货地址列表
 * @param {object} data 
 * @returns 
 */
export const getBaseReceiverAddrList = data => {
  return request({
    url: '/base/rcvr/omsQueryBaseReceiverAddrList',
    method: 'get',
    params: data
  })
}

