<template>
  <el-drawer
    class="v-dialog common-dialog"
    :title="title"
    :visible.sync="show"
    :show-close="true"
    :wrapperClosable="false"
    :modal="true"
    :destroy-on-close="true"
    :close-on-press-escape="!loading"
    :size="sizes"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div class="common-dialog-content">
      <i class="el-icon-full-screen btn-screen" @click="fullDialog"></i>
      <div v-if="show"><slot></slot></div>
      <div class="dialog-footer">
        <slot name="footer"></slot>
        <template v-if="footerBtns && footerBtns.length">
          <template v-for="(btn, i) in footerBtns">
            <SelfLoadingButton
              v-if="'show' in btn ? btn.show() : true"
              :key="i"
              v-bind="btn"
              :click="btn.atClick"
              :disabled="'disabled' in btn ? btn.disabled() : false"
            >
              {{ typeof btn.text === 'function' ? btn.text() : btn.text }}
            </SelfLoadingButton>
          </template>
        </template>
        <template v-else-if="isConfirmType">
          <el-button
            plain
            icon="el-icon-close"
            :loading="loading"
            @click="handleClose('cancel', 'cancelled')"
            >{{ cancelText }}</el-button
          >
          <el-button
            plain
            type="primary"
            icon="el-icon-check"
            :loading="loading"
            @click="handleClose('confirm', 'confirmed')"
            >{{ confirmText }}</el-button
          >
        </template>
        <el-button
          v-else
          plain
          icon="el-icon-close"
          @click="handleClose('close', 'closed')"
          >{{ closeText }}</el-button
        >
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import SelfLoadingButton from './SelfLoadingButton.vue'

/**
 * @type {Object}
 * @description 弹窗类型
 */
const DialogTypes = {
  confirm: 'confirm',
  alert: 'alert'
}

/**
 * v-dialog
 *
 * 通用`el-dialog`弹窗
 *
 * Slots:
 * - `default` 内容
 * - `footer` 底部按钮栏
 *
 * Events:
 *  - `@canceled` 已取消
 *  - `@confirmed` 已确认
 *  - `@closed` 已关闭
 *
 * Callback(props):
 *  - `cancel` 取消前置回调
 *  - `confirm` 确认前置回调
 *
 */
export default {
  components: {
    SelfLoadingButton
  },
  name: 'VDialog',
  props: {
    visible: Boolean,
    // confirm: 取消前置异步回调，返回`false`取消操作
    cancel: Function,
    // confirm: 确认前置异步回调，返回`false`取消操作
    confirm: Function,
    // alert: 关闭前置异步回调，返回`false`取消操作
    close: Function,
    type: {
      type: String,
      default: 'confirm',
      validator: value => Object.values(DialogTypes).includes(value)
    },
    title: {
      type: String,
      default: '确认'
    },
    cancelText: {
      type: String,
      default: '取消'
    },
    confirmText: {
      type: String,
      default: '确定'
    },
    closeText: {
      type: String,
      default: '关闭'
    },
    footerBtns: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      isFullDialog: false, // 抽屉全屏
    }
  },
  computed: {
    ...mapGetters(
      {collapse: 'collapse'}
    ),
    isConfirmType() {
      return this.type === DialogTypes.confirm
    },
    show: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    sizes() {
      if(this.isFullDialog) {
        return this.collapse ? (document.body.clientWidth - 40) : (document.body.clientWidth - 200)
      } else {
        return this.$attrs.size
      }
    }
  },

  deactivated() {
    this.show = false
  },

  methods: {
    async handleClose(type, event) {
      this.loading = true

      let shouldClose = true

      // 前置回调
      const handleCb = this[type]
      if (handleCb) {
        try {
          shouldClose = (await handleCb()) !== false
        } catch (e) {
          shouldClose = false
        }
      }

      // 关闭弹窗
      if (event && shouldClose) {
        this.show = false
        this.$emit(event)
      }

      this.loading = false
    },
    fullDialog() {
      this.isFullDialog = !this.isFullDialog
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-screen {
  position: absolute;
  color: #fff;
  top: 14px;
  right: 80px;
  cursor: pointer;
}
::v-deep .el-drawer {
  &:focus {
    outline: none;
  }
  .el-drawer__header {
    height: 44px;
    padding-bottom: 10px;
    padding-top: 10px;
    background-color: $primary-color;
    margin-bottom: 10px;
    span {
      color: #fff;
      font-size: 14px;
      &:focus {
        outline: none;
      }
    }
    .el-icon-close {
      color: #fff;
    }
  }
  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 60px;
    background: white;
  }
  .common-dialog-content {
    padding: 0 20px;
    height: calc(100vh - 130px);
    overflow: auto;
    width: 100%;
  }
}
</style>
