<template>
  <div
    class="order-manage"
  >
    <search-table
      ref="searchTable"
      labelWidth="70px"
      :loading="loading"
      :searchFields="searchFields"
      :autoBtnList="autoBtnList"
      :fixedBtnList="fixedBtnList"
      :colSize="colSize"
      :total="total"
      :tableData="tableData"
      :tableColumns="tableColumns"
      :baseColumns="baseColumns"
      :sortFields="sortFields"
      :checkboxColumn="true"
      :hasPagination="true"
      :showFooter="true"
      :showSetting="true"
      :showColumnSearch="true"
      :showSortable="true"
      :tableSummary="tableSummary"
      :proxyParams="proxyParams"
      :initFormData="initFormData"
      :selectTableSummary="selectTableSummary"
      :fieldOperateList="fieldOperateList"
      @saveTableField="saveTableField"
      @saveSearchField="saveSearchField"
      @saveSortField="saveSortField"
      @getTableData="getTableData"
    >
    </search-table>
    <order-detail
      ref="orderDetail"
      @getTableData="getTableData"
      
    />
  </div>
</template>

<script>
import SearchTable from '@/components/LcSearchTable'

import config from '@/const/order/order-manage.js'
import orderDetail from './components/orderDetail.vue'

import dayjs from 'dayjs'
import { latestWeek, isParamsNotNull } from '@/utils/util'
import { queryPlanBillList, cancelPlanBill } from '@/api/order'
import { columnSetting } from '@/api/common'
import { orderStatusEnum } from '@/constant/enum.js'
import column from '@/mixins/column.js'
export default {
  name:'OrderManagement',
  components: {
    SearchTable,
    orderDetail
  },
  mixins: [column],
  data() {
    return {
      visible: false,

      loading: false,
      total: 0,
      initFormData: { // 初始化的表单数据
        deliveryDt: latestWeek,
      },
      baseColumn: [], //接口请求的表格配置
      tableColumns: [], //用户自定义的表格配置
      searchFields: [], //用户自定义的search配置
      tableData: [],
      tableSummary: {}, //后端返回的总合计数据
      selectTableData: [], //checkbox选中的表格数据
      colSize: 6, //用户设置展示列比例
      selectTableSummary: config.selectTableSummary, // 需要选中合计的字段
      sortFields: [], // 用户自定义的排序设置
      formParams: {}, // 当前查询的参数
      path: '', // 更新字段名显示接口路径
    }
  },
  computed: {
    // 操作按钮
    autoBtnList() {
      return config.operateList(this)
    },
    // 固定按钮
    fixedBtnList() {
      return config.fixedBtnList(this)
    },
    // 单元格field跳转的处理  key是field，value必须是函数
    fieldOperateList() {
      return {
        planBillId: this.getOrderDetail // 必须是函数
      }
    }
  },

  created() {

  },
  activated () {
    // 从下单成功页面跳转过来 重新查询
    if(this.$route.query.getData == 1) {
      this.getTableData()
    }
  },
  methods: {
    initConfig() {
      this.tableColumns = this.baseColumns.filter(v => v.isShow)
      this.searchFields = this.baseColumns.filter(v => v.isSearchShow)
      this.sortFields = this.tableColumns
        .filter(v => v.sortable)
        .reduce((prev, item) => {
          // if(item.sortList) prev = prev.concat(item.sortList)
          prev.push({
            title: `${item.title}升序`,
            field: `${item.field}-asc`,
          })
          prev.push({
            title: `${item.title}降序`,
            field: `${item.field}-desc`,
          })
          return prev
        }, [])
    },
    // 查看详情
    async getOrderDetail(row, column) {
      console.log(row, column)
      this.$refs.orderDetail.visible = true
      await this.$nextTick()
      this.$refs.orderDetail.type = 'detail'
      this.$refs.orderDetail.$refs.detail.getOrderDetail(row.planBillId)
    },
    // 修改订单
    async editOrderDetail(row, column) {
      let length = this.$refs.searchTable.selectTableData.length
      const selectTableData = this.$refs.searchTable.selectTableData
      if (length) {
        if (length === 1) {
          const orderStatus = orderStatusEnum.find(v => selectTableData[0].orderStatus === v.label)
          if(orderStatus.value === 2) {
            this.$message.error('已接单的订单不能修改！')
            return
          }
          try {
            this.$refs.orderDetail.visible = true
            await this.$nextTick()
            this.$refs.orderDetail.type = 'edit'
            this.$refs.orderDetail.$refs.detail.planBillId = selectTableData[0].planBillId
            this.$refs.orderDetail.$refs.detail.getOrderDetail(selectTableData[0].planBillId)
            this.$refs.orderDetail.$refs.detail.appendToBody = true
          } catch (error) {
            console.log(error)
          }
        } else {
          this.$message.error('只能选中一个!')
        }
      } else {
        this.$message.error('请选中需要修改的订单!')
      }
    },
    // 取消订单
    async cancelOrderDetail(form) {
      // console.log(form)
      let length = this.$refs.searchTable.selectTableData.length
      const selectTableData = this.$refs.searchTable.selectTableData
      if (length) {
        if (length === 1) {
          const orderStatus = orderStatusEnum.find(v => selectTableData[0].orderStatus === v.label)
          if(orderStatus.label === '已接单') {
            this.$message.error('已接单的订单不能修改！')
            return
          }
          try {
            await this.$confirm('确认取消此订单吗?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
            //todos 删除地址
            const res = await cancelPlanBill({planBillId: selectTableData[0].planBillId})
            // if(res.data) {
              this.$message.success('取消成功')
              this.getTableData(this.formParams)
            // }
          } catch (error) {
            console.log(error)
          }
        } else {
          this.$message.error('只能选中一个!')
        }
      } else {
        this.$message.error('请选中需要取消的订单!')
      }
    },
    
    //获取表格数据
    async getTableData(params) {
      if (params) {
        this.formParams = params
      } else {
        params = this.formParams
      }
      params = isParamsNotNull(params)
      if (params?.deliveryDt && params.deliveryDt.length) {
        params.deliveryDtStart = params.deliveryDt[0]
        params.deliveryDtEnd = params.deliveryDt[1]
        // params.deliveryDt = null
      } else {
        if(this.$route.query.getData == 1) {
          return
        }
        this.$message.error('请选择时间')
        return
      }
      this.loading = true
      
      try {
        const res = await queryPlanBillList({ ...params })
        this.tableData = res.data.items
        this.total = res.data.total
        this.tableSummary = res.data.summary
        this.baseColumns = res.data?.tableSettingFields || config.baseColumn(this)
        this.path = res.data?.path
        this.colSize = res.data?.colSize
        this.initConfig()
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    //保存|修改表格头配置
    async saveTableField(fields) {
      // console.log(fields)
      this.tableColumns = fields
      try {
        const res = await columnSetting({
          path: this.path,
          // searchFields: this.searchFields,
          tableFields: fields
        })
        this.getTableData()
      } catch (error) {
        console.log(error)
      }
    },
    //保存|修改搜索字段配置
    async saveSearchField(fields, colSize) {
      // console.log(fields, colSize)
      this.colSize = colSize
      this.searchFields = fields
      try {
        const res = await columnSetting({
          path: this.path,
          searchFields: fields,
          // tableFields: this.tableColumns,
          colSize
        })

      } catch (error) {
        console.log(error)
      }
    },
    //保存|修改排序字段配置
    async saveSortField(fields) {
      console.log(fields)
      this.sortFields = fields
    },
    // 处理数据
    proxyParams(params) {
      console.log(params)
    },
    
    //获取表格头配置
    async getTableField() {
      
    },
    //获取搜索字段配置
    async getSearchField() {
      
    },
    
  }
}
</script>

<style lang="scss" scope>
.order-manage {
  width: 100%;
  height: 100%;
}
</style>

