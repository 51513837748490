<template>
  <div class="add-order">
    <order-detail></order-detail>
  </div>
</template>

<script>
import orderDetail from '@/components/order/OrderDetail.vue'
export default {
  name: 'AddOrder',
  components: { orderDetail },

}
</script>

<style scoped lang="scss">
.add-order {
  max-width: 800px;
  height: 100%;
  
  // padding: 30px 0;
  // box-sizing: border-box;
  // overflow-y: hidden;
  // .form {
  //   max-width: 800px;
  //   height: auto;
  // }
}
</style>