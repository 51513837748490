import { orderStatusEnum } from '@/constant/enum.js'

const searchFields = vm => {
  return [
    {
      keyId: 3, //唯一id //必填
      title: '订单号', //字段文字//必填
      field: 'planBillId', //字段属性//必填
      width: 120, //列宽
      searchType: 'input',
      searchValue: '', //搜索绑定值
      renderType: 'achnor', //achnor,status,option
      sortable: true, //是否排序
      sortList: [
        {
          keyId: '3-1',
          title: '订单号升序',
          field: 'wayId-asc',
        },
        {
          keyId: '3-2',
          title: '订单号降序',
          field: 'wayId-desc',
        }
      ],
      multiple: true, //是否是文本域查询字段
      textColor: '#0079FE', //表格渲染状态的颜色
      // fixed: 'left', //列固定，//切记如果存在合计的表格，尽量不要使用固定列，避免出现合计数据错位的情况
      multiField: true, //是否是单号字段
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 11,
      title: '发货日期',
      field: 'deliveryDt',
      width: 160,
      searchType: 'daterange',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      sortList: [
        {
          keyId: '11-1',
          title: '发货日期升序',
          field: 'deliveryDt-asc',
        },
        // {
        //   keyId: '11-2',
        //   title: '发货日期降序',
        //   field: 'deliveryDt-desc',
        // }
      ],
      copy: true, //是否拥有复制功能
      columnType: 'date', // 筛选自定义
      filterable: true, // 是否可筛选
    },
    {
      keyId: 26,
      title: '收货单位',
      field: 'rcvrNm',
      width: 180,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 27,
      title: '收货人',
      field: 'rcvrCtcts',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 28,
      title: '到站',
      field: 'destNm',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
  ]
}

const baseColumn = vm => {
  return [
    
    // {
    //   keyId: 4,
    //   title: '短驳单号',
    //   field: 'transId',
    //   width: 120,
    //   searchType: 'input',
    //   searchValue: '', //搜索绑定值
    //   renderType: 'achnor', //achnor,status,option
    //   sortable: true, //是否排序
    //   textColor: '#409eff', //表格渲染状态的颜色
    //   multiField: true, //是否是单号字段
    //   copy: true, //是否拥有复制功能
    // },
    {
      keyId: 6,
      title: '订单状态',
      field: 'orderStatus', // key
      width: 120, // table单元格宽度
      // minWidth: 120, // 最小宽度
      searchType: 'select',
      searchValue: '', //搜索绑定值
      renderType: 'status', //achnor,status,option
      sortable: true, //是否排序
      fixed: 'left', //列固定，//切记如果存在合计的表格，尽量不要使用固定列，避免出现合计数据错位的情况
      sortList: [
        {
          keyId: '6-1',
          title: '订单状态升序',
          field: 'status-asc',
        },
        {
          keyId: '6-2',
          title: '订单状态降序',
          field: 'status-desc',
        }
      ],
      textColor: '#e6a23c',
      copy: true, //是否拥有复制功能
      selectOptions: orderStatusEnum, // 下拉select
      columnType: 'select',
      filterable: true, // 自定义筛选
      // summable: true, // 选中计算的表格，合计
    },
    // {
    //   keyId: 3, //唯一id //必填
    //   title: '运单号', //字段文字//必填
    //   field: 'planBillId', //字段属性//必填
    //   width: 120, //列宽
    //   searchType: 'input',
    //   searchValue: '', //搜索绑定值
    //   renderType: 'achnor', //achnor,status,option
    //   sortable: true, //是否排序
    //   sortList: [
    //     {
    //       keyId: '3-1',
    //       title: '运单号升序',
    //       field: 'wayId-asc',
    //     },
    //     {
    //       keyId: '3-2',
    //       title: '运单号降序',
    //       field: 'wayId-desc',
    //     }
    //   ],
    //   multiField: true, //是否是文本域查询字段
    //   textColor: '#0079FE', //表格渲染状态的颜色
    //   // fixed: 'left', //列固定，//切记如果存在合计的表格，尽量不要使用固定列，避免出现合计数据错位的情况
    //   multiField: true, //是否是单号字段
    //   copy: true, //是否拥有复制功能
    // },
    {
      keyId: 7,
      title: '订单号',
      field: 'planBillId',
      width: 140,
      searchType: 'input',
      renderType: 'achnor', //achnor,status,option
      textColor: '#0079FE', //表格渲染状态的颜色
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      sortList: [
        {
          keyId: '7-1',
          title: '订单号升序',
          field: 'orderNo-asc',
        },
        {
          keyId: '7-2',
          title: '订单号降序',
          field: 'orderNo-desc',
        }
      ],
      copy: true, //是否拥有复制功能
      columnType: 'normal',
      filterable: true,
    },
    {
      keyId: 11,
      title: '发货日期',
      field: 'deliveryDt',
      width: 160,
      searchType: 'daterange',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      sortList: [
        {
          keyId: '11-1',
          title: '发货日期升序',
          field: 'deliveryDt-asc',
        },
        // {
        //   keyId: '11-2',
        //   title: '发货日期降序',
        //   field: 'deliveryDt-desc',
        // }
      ],
      copy: true, //是否拥有复制功能
      columnType: 'date', // 筛选自定义
      filterable: true, // 是否可筛选
    },

    {
      keyId: 8,
      title: '厂家单号',
      field: 'cnorNbr',
      width: 160,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      sortList: [
        {
          keyId: '8-1',
          title: '厂家单号升序',
          field: 'cnorNbr-asc',
        },
        {
          keyId: '8-2',
          title: '厂家单号降序',
          field: 'cnorNbr-desc',
        }
      ],
      copy: true, //是否拥有复制功能
      // tip: '厂家的单号',
      columnType: 'integer',
    },

    {
      keyId: 10,
      title: '数量',
      field: 'instQty',
      width: 160,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      sortList: [
        {
          keyId: '10-1',
          title: '数量升序',
          field: 'count-asc',
        },
        {
          keyId: '10-2',
          title: '数量降序',
          field: 'count-desc',
        }
      ],
      copy: true, //是否拥有复制功能
      summable: true, // 选中计算的表格
    },
    {
      keyId: 19,
      title: '净重',
      field: 'instNetWei',
      width: 100,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      sortList: [
        {
          keyId: '19-1',
          title: '净重升序',
          field: 'instNetWei-asc',
        },
        {
          keyId: '19-2',
          title: '净重降序',
          field: 'instNetWei-desc',
        }
      ],
      copy: true, //是否拥有复制功能
      summable: true,
      // tip: '净重值'
    },
    {
      keyId: 20,
      title: '毛重',
      field: 'instWei',
      width: 100,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      sortList: [
        {
          keyId: '10-1',
          title: '数量升序',
          field: 'count-asc',
        },
        {
          keyId: '10-2',
          title: '数量降序',
          field: 'count-desc',
        }
      ],
      copy: true, //是否拥有复制功能
      summable: true,
      columnType: 'integer',
      filterable: true,
    },
    {
      keyId: 21,
      title: '体积',
      field: 'instVol',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
      summable: true,
    },
    {
      keyId: 26,
      title: '收货单位',
      field: 'rcvrNm',
      minWidth: 180,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 27,
      title: '收货人',
      field: 'rcvrCtcts',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 28,
      title: '收货电话',
      field: 'rcvrTel',
      width: 180,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 29,
      title: '收货地址',
      field: 'rcvrAddrNm',
      minWidth: 240,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
  ]
}

const operateList = vm => {
  return [
    { name: '修改', btnFn: vm.editOrderDetail, plain: true, icon: 'el-icon-edit-outline' },
    { name: '取消', btnFn: vm.cancelOrderDetail, plain: true, icon: 'el-icon-document-remove' },
    // { 
    //   name: '下拉',
    //   btnFn: vm.getOrderDetail,
    //   selectType: true,
    //   plain: true,
    //   icon: 'el-icon-document-remove',
    //   children: [
    //     {
    //       name: '修改', btnFn: vm.getOrderDetail, plain: true, icon: 'el-icon-edit-outline' 
    //     }
    //   ]
    // },
  ]
}
// 固定按钮
const fixedBtnList = vm => {
  // return [
  //   { name: '导出', btnFn: vm.getOrderDetail, plain: true, icon: 'el-icon-document' },
  // ]
}

const searchFields2 = [
  {
  "keyId":11,
  "title":"发货日期",
  "field":"deliveryDt",
  "width":200,
  "fieldType":null,
  "searchType":"daterange",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":7,
  "title":"油卡",
  "field":"payCardCount",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":10,
  "title":"外包日期",
  "field":"outDt",
  "width":200,
  "fieldType":null,
  "searchType":"daterange",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":12,
  "title":"预计到达日期",
  "field":"expectDt",
  "width":200,
  "fieldType":null,
  "searchType":"daterange",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":13,
  "title":"来源",
  "field":"type",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":[
  {
  "label":"短驳",
  "value":3
  },
  {
  "label":"外包",
  "value":4
  },
  {
  "label":"合并",
  "value":5
  }
  ],
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":"#67c23a",
  "fixed":null,
  "renderType":"status",
  "copy":true
  },
  {
  "keyId":14,
  "title":"发货单位",
  "field":"cnor",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":8,
  "title":"油卡金额",
  "field":"payCardFee",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":6,
  "title":"状态",
  "field":"status",
  "width":120,
  "fieldType":null,
  "searchType":"select",
  "searchValue":"",
  "selectOptions":[
  {
  "label":"新开单",
  "value":1
  },
  {
  "label":"提货中",
  "value":11
  },
  {
  "label":"提货入库",
  "value":22
  },
  {
  "label":"送货中",
  "value":12
  },
  {
  "label":"已发车",
  "value":13
  },
  {
  "label":"已外包",
  "value":14
  },
  {
  "label":"已短驳",
  "value":15
  },
  {
  "label":"已回单",
  "value":21
  },
  {
  "label":"发车入库",
  "value":23
  },
  {
  "label":"送货出库",
  "value":24
  },
  {
  "label":"承运商提货",
  "value":28
  },
  {
  "label":"已到达",
  "value":50
  },
  {
  "label":"运输中",
  "value":51
  },
  {
  "label":"已签收",
  "value":52
  },
  {
  "label":"异常",
  "value":53
  },
  {
  "label":"大雾",
  "value":54
  },
  {
  "label":"拥堵",
  "value":55
  },
  {
  "label":"事故",
  "value":56
  },
  {
  "label":"正常签收",
  "value":57
  },
  {
  "label":"异常签收",
  "value":58
  },
  {
  "label":"拒绝签收",
  "value":59
  },
  {
  "label":"其他",
  "value":60
  }
  ],
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":"#e6a23c",
  "fixed":null,
  "renderType":"status",
  "copy":true
  },
  {
  "keyId":9,
  "title":"充值状态",
  "field":"oilPayStatus",
  "width":120,
  "fieldType":null,
  "searchType":"select",
  "searchValue":"",
  "selectOptions":[
  {
  "label":"未分配",
  "value":0
  },
  {
  "label":"未充值",
  "value":1
  },
  {
  "label":"已充值",
  "value":2
  },
  {
  "label":"部分充值",
  "value":3
  }
  ],
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":"#e6a23c",
  "fixed":null,
  "renderType":"status",
  "copy":true
  },
  {
  "keyId":34,
  "title":"外包单备注",
  "field":"remark",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":35,
  "title":"运单备注",
  "field":"wbRemark",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":33,
  "title":"到站电话",
  "field":"destTel",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":32,
  "title":"到站",
  "field":"dest",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":31,
  "title":"发站电话",
  "field":"strtTel",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":30,
  "title":"发站",
  "field":"strt",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":29,
  "title":"收货地址",
  "field":"rcvrAddr",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":28,
  "title":"收货电话",
  "field":"rcvrTel",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":27,
  "title":"收货人",
  "field":"rcvrCtcts",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":26,
  "title":"收货单位",
  "field":"rcvrNm",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":25,
  "title":"承运费",
  "field":"outFee",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":24,
  "title":"外包付款方式",
  "field":"outPayTp",
  "width":150,
  "fieldType":null,
  "searchType":"select",
  "searchValue":"",
  "selectOptions":[
  {
  "label":"现付",
  "value":1
  },
  {
  "label":"到付",
  "value":2
  },
  {
  "label":"回付",
  "value":3
  },
  {
  "label":"多笔付",
  "value":4
  }
  ],
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":"#67c23a",
  "fixed":null,
  "renderType":"status",
  "copy":true
  },
  {
  "keyId":23,
  "title":"承运商",
  "field":"crirNm",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":22,
  "title":"代付状态",
  "field":"payAnotherStatus",
  "width":120,
  "fieldType":null,
  "searchType":"select",
  "searchValue":"",
  "selectOptions":[
  {
  "label":"未代付",
  "value":0
  },
  {
  "label":"已代付",
  "value":1
  }
  ],
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":"#e6a23c",
  "fixed":null,
  "renderType":"status",
  "copy":true
  },
  {
  "keyId":21,
  "title":"体积",
  "field":"instVol",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":20,
  "title":"重量",
  "field":"instWei",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":19,
  "title":"件数",
  "field":"instQty",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":18,
  "title":"货物名称",
  "field":"cgNm",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":16,
  "title":"运单付款方式",
  "field":"payTp",
  "width":150,
  "fieldType":null,
  "searchType":"select",
  "searchValue":"",
  "selectOptions":[
  {
  "label":"现付",
  "value":1
  },
  {
  "label":"到付",
  "value":2
  },
  {
  "label":"免费",
  "value":3
  },
  {
  "label":"月结",
  "value":4
  },
  {
  "label":"多笔付",
  "value":6
  }
  ],
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":"#67c23a",
  "fixed":null,
  "renderType":"status",
  "copy":true
  },
  {
  "keyId":17,
  "title":"运单到付金额",
  "field":"collectFee",
  "width":150,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":15,
  "title":"厂家单号",
  "field":"cnorNbr",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":4,
  "title":"短驳单号",
  "field":"transId",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":true,
  "fieldCanClick":false,
  "textColor":"#409eff",
  "fixed":null,
  "renderType":"achnor",
  "copy":true
  },
  {
  "keyId":3,
  "title":"外包单号",
  "field":"outId",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":true,
  "fieldCanClick":false,
  "textColor":"#409eff",
  "fixed":null,
  "renderType":"achnor",
  "copy":true
  },
  {
  "keyId":5,
  "title":"调度类型",
  "field":"schedulingSource",
  "width":120,
  "fieldType":null,
  "searchType":"select",
  "searchValue":"",
  "selectOptions":[
  {
  "label":"调度",
  "value":1
  },
  {
  "label":"预调度",
  "value":2
  }
  ],
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":"#67c23a",
  "fixed":null,
  "renderType":"status",
  "copy":true
  }
  ]

const tableFields = [
  {
  "keyId":3,
  "title":"外包单号",
  "field":"outId",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":true,
  "fieldCanClick":false,
  "textColor":"#409eff",
  "fixed":null,
  "renderType":"achnor",
  "copy":true
  },
  {
  "keyId":4,
  "title":"短驳单号",
  "field":"transId",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":true,
  "fieldCanClick":false,
  "textColor":"#409eff",
  "fixed":null,
  "renderType":"achnor",
  "copy":true
  },
  {
  "keyId":7,
  "title":"油卡",
  "field":"payCardCount",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":10,
  "title":"外包日期",
  "field":"outDt",
  "width":200,
  "fieldType":null,
  "searchType":"daterange",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":11,
  "title":"发货日期",
  "field":"deliveryDt",
  "width":200,
  "fieldType":null,
  "searchType":"daterange",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":12,
  "title":"预计到达日期",
  "field":"expectDt",
  "width":200,
  "fieldType":null,
  "searchType":"daterange",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":13,
  "title":"来源",
  "field":"type",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":[
  {
  "label":"短驳",
  "value":3
  },
  {
  "label":"外包",
  "value":4
  },
  {
  "label":"合并",
  "value":5
  }
  ],
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":"#67c23a",
  "fixed":null,
  "renderType":"status",
  "copy":true
  },
  {
  "keyId":9,
  "title":"充值状态",
  "field":"oilPayStatus",
  "width":120,
  "fieldType":null,
  "searchType":"select",
  "searchValue":"",
  "selectOptions":[
  {
  "label":"未分配",
  "value":0
  },
  {
  "label":"未充值",
  "value":1
  },
  {
  "label":"已充值",
  "value":2
  },
  {
  "label":"部分充值",
  "value":3
  }
  ],
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":"#e6a23c",
  "fixed":null,
  "renderType":"status",
  "copy":true
  },
  {
  "keyId":14,
  "title":"发货单位",
  "field":"cnor",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":8,
  "title":"油卡金额",
  "field":"payCardFee",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":6,
  "title":"状态",
  "field":"status",
  "width":120,
  "fieldType":null,
  "searchType":"select",
  "searchValue":"",
  "selectOptions":[
  {
  "label":"新开单",
  "value":1
  },
  {
  "label":"提货中",
  "value":11
  },
  {
  "label":"提货入库",
  "value":22
  },
  {
  "label":"送货中",
  "value":12
  },
  {
  "label":"已发车",
  "value":13
  },
  {
  "label":"已外包",
  "value":14
  },
  {
  "label":"已短驳",
  "value":15
  },
  {
  "label":"已回单",
  "value":21
  },
  {
  "label":"发车入库",
  "value":23
  },
  {
  "label":"送货出库",
  "value":24
  },
  {
  "label":"承运商提货",
  "value":28
  },
  {
  "label":"已到达",
  "value":50
  },
  {
  "label":"运输中",
  "value":51
  },
  {
  "label":"已签收",
  "value":52
  },
  {
  "label":"异常",
  "value":53
  },
  {
  "label":"大雾",
  "value":54
  },
  {
  "label":"拥堵",
  "value":55
  },
  {
  "label":"事故",
  "value":56
  },
  {
  "label":"正常签收",
  "value":57
  },
  {
  "label":"异常签收",
  "value":58
  },
  {
  "label":"拒绝签收",
  "value":59
  },
  {
  "label":"其他",
  "value":60
  }
  ],
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":"#e6a23c",
  "fixed":null,
  "renderType":"status",
  "copy":true
  },
  {
  "keyId":5,
  "title":"调度类型",
  "field":"schedulingSource",
  "width":120,
  "fieldType":null,
  "searchType":"select",
  "searchValue":"",
  "selectOptions":[
  {
  "label":"调度",
  "value":1
  },
  {
  "label":"预调度",
  "value":2
  }
  ],
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":"#67c23a",
  "fixed":null,
  "renderType":"status",
  "copy":true
  },
  {
  "keyId":15,
  "title":"厂家单号",
  "field":"cnorNbr",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":16,
  "title":"运单付款方式",
  "field":"payTp",
  "width":150,
  "fieldType":null,
  "searchType":"select",
  "searchValue":"",
  "selectOptions":[
  {
  "label":"现付",
  "value":1
  },
  {
  "label":"到付",
  "value":2
  },
  {
  "label":"免费",
  "value":3
  },
  {
  "label":"月结",
  "value":4
  },
  {
  "label":"多笔付",
  "value":6
  }
  ],
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":"#67c23a",
  "fixed":null,
  "renderType":"status",
  "copy":true
  },
  {
  "keyId":17,
  "title":"运单到付金额",
  "field":"collectFee",
  "width":150,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":18,
  "title":"货物名称",
  "field":"cgNm",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":19,
  "title":"件数",
  "field":"instQty",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":20,
  "title":"重量",
  "field":"instWei",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":21,
  "title":"体积",
  "field":"instVol",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":22,
  "title":"代付状态",
  "field":"payAnotherStatus",
  "width":120,
  "fieldType":null,
  "searchType":"select",
  "searchValue":"",
  "selectOptions":[
  {
  "label":"未代付",
  "value":0
  },
  {
  "label":"已代付",
  "value":1
  }
  ],
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":"#e6a23c",
  "fixed":null,
  "renderType":"status",
  "copy":true
  },
  {
  "keyId":23,
  "title":"承运商",
  "field":"crirNm",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":25,
  "title":"承运费",
  "field":"outFee",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":24,
  "title":"外包付款方式",
  "field":"outPayTp",
  "width":150,
  "fieldType":null,
  "searchType":"select",
  "searchValue":"",
  "selectOptions":[
  {
  "label":"现付",
  "value":1
  },
  {
  "label":"到付",
  "value":2
  },
  {
  "label":"回付",
  "value":3
  },
  {
  "label":"多笔付",
  "value":4
  }
  ],
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":"#67c23a",
  "fixed":null,
  "renderType":"status",
  "copy":true
  },
  {
  "keyId":26,
  "title":"收货单位",
  "field":"rcvrNm",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":27,
  "title":"收货人",
  "field":"rcvrCtcts",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":28,
  "title":"收货电话",
  "field":"rcvrTel",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":29,
  "title":"收货地址",
  "field":"rcvrAddr",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":30,
  "title":"发站",
  "field":"strt",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":31,
  "title":"发站电话",
  "field":"strtTel",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":32,
  "title":"到站",
  "field":"dest",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":33,
  "title":"到站电话",
  "field":"destTel",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":34,
  "title":"外包单备注",
  "field":"remark",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  },
  {
  "keyId":35,
  "title":"运单备注",
  "field":"wbRemark",
  "width":120,
  "fieldType":null,
  "searchType":"input",
  "searchValue":"",
  "selectOptions":null,
  "showField":false,
  "sortable":true,
  "multiField":false,
  "fieldCanClick":false,
  "textColor":null,
  "fixed":null,
  "renderType":null,
  "copy":true
  }
  ]
const colSize = 6

const summary = {
  "instVol":16.0000000000,
  "payCardFee":4000.0000000000,
  "outFee":28871.0000000000,
  "instWei":33.7750000000,
  "collectFee":1225.0000000000,
  "outId":22,
  "instQty":53
}
const total = 22
const items = [
  {
  "outId":"WB211229002",
  orderNo: '211225002',
  "crirId":167,
  "strtNm":null,
  "destNm":null,
  "destId":null,
  "payStatus":0,
  "oilPayStatus":null,
  "payCardCount":"0",

  "outFee":100.0000000000,
  "depositFee":0E-10,
  "outDt":"2021-12-29",
  "type":"外包",
  "remark":"",
  "tranFlag":null,
  "strt":"杭州市",
  "trafer":null,
  "bourn":null,
  "carrierId":null,
  "carrierNm":null,
  "carrierFee":null,
  "carrierPayTp":null,
  "status":"已外包",
  "coId":null,
  "createDt":null,
  "createUsr":null,
  "lastDt":"2021-12-29",
  "lastUsr":null,
  "state":null,
  "instQty":1,
  "instGrossWei":null,
  "instWei":1.0000000000,
  "instVol":1.0000000000,
  "expectDt":"2021-12-27",
  "dest":"安陆市",
  "payTp":"月结",
  "crirNm":"通泰物流",
  "cnor":"欧文斯科宁111",
  "cnorCtcts":"方青亮",
  "cnorTel":"13867139235",
  "rcvrAddr":" 桐乡市凤鸣街道同福东路688号",
  "cgNm":"复合材料",
  "strtCtcts":"张家俊",
  "strtTel":"15497668342",
  "destCtcts":null,
  "destTel":null,
  "rcvr":"桐乡恒力",
  "rcvrCtcts":"小吕 ",
  "rcvrTel":"13372338939",
  "schedulingSource":"调度",
  "parOutId":"0",
  "mergeBillStatus":"0",
  "carrierBillStatus":"1",
  "nums":null,
  "wayId":"TT-211229003",
  "billStatus":null,
  "accessCode":null,
  "wayBillEditStatus":"0",
  "deliveryDt":"2021-12-27",
  "cnorNbr":"",
  "transId":null,
  "payAnotherStatus":"未代付",
  "outPayTp":"回付",
  "collectFee":0E-10,
  "advance":null,
  "advancePaidAmount":null,
  "advanceUnpaidAmount":null,
  "advanceSettlementStatus":null,
  "remitFee":null,
  "remitFeePaidAmount":null,
  "remitFeeUnpaidAmount":null,
  "remitFeeSettlementStatus":null,
  "receiptFee":null,
  "receiptFeePaidAmount":null,
  "receiptFeeUnpaidAmount":null,
  "receiptFeeSettlementStatus":null,
  "payCardFee":0E-10,
  "paycardAmount":null,
  "woodAmount":null,
  "woodAmountBack":null,
  "woodDeposit":null,
  "informationFee":null,
  "informationFeeStatus":null,
  "insuranceFee":null,
  "otherFee":null,
  "apportionType":null,
  "wbRemark":""
  },
  {
  "outId":"WB211229001",
  "crirId":167,
  "strtNm":null,
  "destNm":null,
  "destId":null,
  "payStatus":0,
  "oilPayStatus":null,
  "payCardCount":"0",
  "outFee":100.0000000000,
  "depositFee":0E-10,
  "outDt":"2021-12-29",
  "type":"外包",
  "remark":"",
  "tranFlag":null,
  "strt":"临安区",
  "trafer":null,
  "bourn":null,
  "carrierId":null,
  "carrierNm":null,
  "carrierFee":null,
  "carrierPayTp":null,
  "status":"已外包",
  "coId":null,
  "createDt":null,
  "createUsr":null,
  "lastDt":"2021-12-29",
  "lastUsr":null,
  "state":null,
  "instQty":2,
  "instGrossWei":null,
  "instWei":1.0000000000,
  "instVol":1.0000000000,
  "expectDt":"2021-12-27",
  "dest":"温州市",
  "payTp":"月结",
  "crirNm":"通泰物流",
  "cnor":"欧文斯科宁111",
  "cnorCtcts":"方青亮",
  "cnorTel":"13867139235",
  "rcvrAddr":"上海市浦东新区高桥镇巴斯夫(中国)有限公司巴斯夫新材料有限公司上海市浦东新区江心沙路在地图中查看9号",
  "cgNm":"复合材料",
  "strtCtcts":"张家俊",
  "strtTel":"15497668342",
  "destCtcts":null,
  "destTel":null,
  "rcvr":"浦东进仓",
  "rcvrCtcts":"黄建峰 ",
  "rcvrTel":"13501629011",
  "schedulingSource":"调度",
  "parOutId":"0",
  "mergeBillStatus":"0",
  "carrierBillStatus":"1",
  "nums":null,
  "wayId":"TT-211229002",
  "billStatus":null,
  "accessCode":null,
  "wayBillEditStatus":"0",
  "deliveryDt":"2021-12-27",
  "cnorNbr":"",
  "transId":null,
  "payAnotherStatus":"未代付",
  "outPayTp":"回付",
  "collectFee":0E-10,
  "advance":null,
  "advancePaidAmount":null,
  "advanceUnpaidAmount":null,
  "advanceSettlementStatus":null,
  "remitFee":null,
  "remitFeePaidAmount":null,
  "remitFeeUnpaidAmount":null,
  "remitFeeSettlementStatus":null,
  "receiptFee":null,
  "receiptFeePaidAmount":null,
  "receiptFeeUnpaidAmount":null,
  "receiptFeeSettlementStatus":null,
  "payCardFee":0E-10,
  "paycardAmount":null,
  "woodAmount":null,
  "woodAmountBack":null,
  "woodDeposit":null,
  "informationFee":null,
  "informationFeeStatus":null,
  "insuranceFee":null,
  "otherFee":null,
  "apportionType":null,
  "wbRemark":""
  },
  {
  "outId":"WB211229003",
  "crirId":166,
  "strtNm":null,
  "destNm":null,
  "destId":null,
  "payStatus":0,
  "oilPayStatus":null,
  "payCardCount":"0",
  "outFee":100.0000000000,
  "depositFee":0E-10,
  "outDt":"2021-12-29",
  "type":"外包",
  "remark":"",
  "tranFlag":null,
  "strt":"杭州市",
  "trafer":null,
  "bourn":null,
  "carrierId":null,
  "carrierNm":null,
  "carrierFee":null,
  "carrierPayTp":null,
  "status":"已外包",
  "coId":null,
  "createDt":null,
  "createUsr":null,
  "lastDt":"2021-12-29",
  "lastUsr":null,
  "state":null,
  "instQty":2,
  "instGrossWei":null,
  "instWei":2.0000000000,
  "instVol":2.0000000000,
  "expectDt":"2021-12-29",
  "dest":"乌鲁木齐市",
  "payTp":"多笔付",
  "crirNm":"杭州一运物流",
  "cnor":"杭州西子",
  "cnorCtcts":"高强",
  "cnorTel":"13675866678",
  "rcvrAddr":"天津空港开发区",
  "cgNm":"周转箱",
  "strtCtcts":"联系人",
  "strtTel":"18667005",
  "destCtcts":null,
  "destTel":null,
  "rcvr":"天津西子",
  "rcvrCtcts":"胡晓春",
  "rcvrTel":"13989455515",
  "schedulingSource":"调度",
  "parOutId":"0",
  "mergeBillStatus":"0",
  "carrierBillStatus":"0",
  "nums":null,
  "wayId":"TT-211229004",
  "billStatus":null,
  "accessCode":null,
  "wayBillEditStatus":"0",
  "deliveryDt":"2021-12-29",
  "cnorNbr":"",
  "transId":null,
  "payAnotherStatus":"未代付",
  "outPayTp":"多笔付",
  "collectFee":500.0000000000,
  "advance":null,
  "advancePaidAmount":null,
  "advanceUnpaidAmount":null,
  "advanceSettlementStatus":null,
  "remitFee":null,
  "remitFeePaidAmount":null,
  "remitFeeUnpaidAmount":null,
  "remitFeeSettlementStatus":null,
  "receiptFee":null,
  "receiptFeePaidAmount":null,
  "receiptFeeUnpaidAmount":null,
  "receiptFeeSettlementStatus":null,
  "payCardFee":0E-10,
  "paycardAmount":null,
  "woodAmount":null,
  "woodAmountBack":null,
  "woodDeposit":null,
  "informationFee":null,
  "informationFeeStatus":null,
  "insuranceFee":null,
  "otherFee":null,
  "apportionType":null,
  "wbRemark":""
  },
  {
  "outId":"WB211229004",
  "crirId":166,
  "strtNm":null,
  "destNm":null,
  "destId":null,
  "payStatus":0,
  "oilPayStatus":null,
  "payCardCount":"0",
  "outFee":100.0000000000,
  "depositFee":0E-10,
  "outDt":"2021-12-29",
  "type":"外包",
  "remark":"",
  "tranFlag":null,
  "strt":"杭州市",
  "trafer":null,
  "bourn":null,
  "carrierId":null,
  "carrierNm":null,
  "carrierFee":null,
  "carrierPayTp":null,
  "status":"已外包",
  "coId":null,
  "createDt":null,
  "createUsr":null,
  "lastDt":"2021-12-29",
  "lastUsr":null,
  "state":null,
  "instQty":1,
  "instGrossWei":null,
  "instWei":1.0000000000,
  "instVol":1.0000000000,
  "expectDt":"2021-12-29",
  "dest":"安陆市",
  "payTp":"多笔付",
  "crirNm":"杭州一运物流",
  "cnor":"欧文斯科宁111",
  "cnorCtcts":"方青亮",
  "cnorTel":"13867139235",
  "rcvrAddr":"上海市浦东新区高桥镇巴斯夫(中国)有限公司巴斯夫新材料有限公司上海市浦东新区江心沙路在地图中查看9号",
  "cgNm":"复合材料",
  "strtCtcts":"联系人",
  "strtTel":"18667005",
  "destCtcts":null,
  "destTel":null,
  "rcvr":"浦东进仓",
  "rcvrCtcts":"黄建峰 ",
  "rcvrTel":"13501629011",
  "schedulingSource":"调度",
  "parOutId":"0",
  "mergeBillStatus":"0",
  "carrierBillStatus":"0",
  "nums":null,
  "wayId":"TT-211229005",
  "billStatus":null,
  "accessCode":null,
  "wayBillEditStatus":"0",
  "deliveryDt":"2021-12-29",
  "cnorNbr":"",
  "transId":null,
  "payAnotherStatus":"未代付",
  "outPayTp":"多笔付",
  "collectFee":700.0000000000,
  "advance":null,
  "advancePaidAmount":null,
  "advanceUnpaidAmount":null,
  "advanceSettlementStatus":null,
  "remitFee":null,
  "remitFeePaidAmount":null,
  "remitFeeUnpaidAmount":null,
  "remitFeeSettlementStatus":null,
  "receiptFee":null,
  "receiptFeePaidAmount":null,
  "receiptFeeUnpaidAmount":null,
  "receiptFeeSettlementStatus":null,
  "payCardFee":0E-10,
  "paycardAmount":null,
  "woodAmount":null,
  "woodAmountBack":null,
  "woodDeposit":null,
  "informationFee":null,
  "informationFeeStatus":null,
  "insuranceFee":null,
  "otherFee":null,
  "apportionType":null,
  "wbRemark":""
  },
  {
  "outId":"WB211228005",
  "crirId":168,
  "strtNm":null,
  "destNm":null,
  "destId":null,
  "payStatus":0,
  "oilPayStatus":null,
  "payCardCount":"0",
  "outFee":3333.0000000000,
  "depositFee":0E-10,
  "outDt":"2021-12-28",
  "type":"外包",
  "remark":"",
  "tranFlag":null,
  "strt":"杭州市",
  "trafer":null,
  "bourn":null,
  "carrierId":null,
  "carrierNm":null,
  "carrierFee":null,
  "carrierPayTp":null,
  "status":"已外包",
  "coId":null,
  "createDt":null,
  "createUsr":null,
  "lastDt":"2021-12-28",
  "lastUsr":null,
  "state":null,
  "instQty":2,
  "instGrossWei":null,
  "instWei":2.0000000000,
  "instVol":2.0000000000,
  "expectDt":"2021-12-23",
  "dest":"武汉市",
  "payTp":"月结",
  "crirNm":"德志物流",
  "cnor":"欧文斯科宁111",
  "cnorCtcts":"方青亮",
  "cnorTel":"13867139235",
  "rcvrAddr":"11",
  "cgNm":"复合材料",
  "strtCtcts":"张森",
  "strtTel":"13185539999",
  "destCtcts":null,
  "destTel":null,
  "rcvr":"江苏沃特新材料",
  "rcvrCtcts":"11",
  "rcvrTel":"11",
  "schedulingSource":"调度",
  "parOutId":"0",
  "mergeBillStatus":"0",
  "carrierBillStatus":"0",
  "nums":null,
  "wayId":"TT-211223008",
  "billStatus":null,
  "accessCode":null,
  "wayBillEditStatus":"0",
  "deliveryDt":"2021-12-23",
  "cnorNbr":"",
  "transId":null,
  "payAnotherStatus":"未代付",
  "outPayTp":"回付",
  "collectFee":0E-10,
  "advance":null,
  "advancePaidAmount":null,
  "advanceUnpaidAmount":null,
  "advanceSettlementStatus":null,
  "remitFee":null,
  "remitFeePaidAmount":null,
  "remitFeeUnpaidAmount":null,
  "remitFeeSettlementStatus":null,
  "receiptFee":null,
  "receiptFeePaidAmount":null,
  "receiptFeeUnpaidAmount":null,
  "receiptFeeSettlementStatus":null,
  "payCardFee":0E-10,
  "paycardAmount":null,
  "woodAmount":null,
  "woodAmountBack":null,
  "woodDeposit":null,
  "informationFee":null,
  "informationFeeStatus":null,
  "insuranceFee":null,
  "otherFee":null,
  "apportionType":null,
  "wbRemark":""
  },
  {
  "outId":"WB211228004",
  "crirId":167,
  "strtNm":null,
  "destNm":null,
  "destId":null,
  "payStatus":0,
  "oilPayStatus":null,
  "payCardCount":"0",
  "outFee":1.0000000000,
  "depositFee":0E-10,
  "outDt":"2021-12-28",
  "type":"外包",
  "remark":"",
  "tranFlag":null,
  "strt":"杭州市",
  "trafer":null,
  "bourn":null,
  "carrierId":null,
  "carrierNm":null,
  "carrierFee":null,
  "carrierPayTp":null,
  "status":"已外包",
  "coId":null,
  "createDt":null,
  "createUsr":null,
  "lastDt":"2021-12-28",
  "lastUsr":null,
  "state":null,
  "instQty":1,
  "instGrossWei":null,
  "instWei":0.1000000000,
  "instVol":0E-10,
  "expectDt":"2021-12-04",
  "dest":"泉州市",
  "payTp":"月结",
  "crirNm":"通泰物流",
  "cnor":"浙江元通线缆制造有限公司",
  "cnorCtcts":"元通电缆",
  "cnorTel":"057189721086",
  "rcvrAddr":"福建省泉州市德化县城东三期华茂陶瓷一楼",
  "cgNm":"电缆",
  "strtCtcts":"张家俊",
  "strtTel":"15497668342",
  "destCtcts":null,
  "destTel":null,
  "rcvr":"福州晴天电线电缆有限公司",
  "rcvrCtcts":"客户",
  "rcvrTel":"17750738880",
  "schedulingSource":"调度",
  "parOutId":"0",
  "mergeBillStatus":"0",
  "carrierBillStatus":"0",
  "nums":null,
  "wayId":"TT-211228004",
  "billStatus":null,
  "accessCode":null,
  "wayBillEditStatus":"0",
  "deliveryDt":"2021-12-01",
  "cnorNbr":"",
  "transId":null,
  "payAnotherStatus":"未代付",
  "outPayTp":"回付",
  "collectFee":0E-10,
  "advance":null,
  "advancePaidAmount":null,
  "advanceUnpaidAmount":null,
  "advanceSettlementStatus":null,
  "remitFee":null,
  "remitFeePaidAmount":null,
  "remitFeeUnpaidAmount":null,
  "remitFeeSettlementStatus":null,
  "receiptFee":null,
  "receiptFeePaidAmount":null,
  "receiptFeeUnpaidAmount":null,
  "receiptFeeSettlementStatus":null,
  "payCardFee":0E-10,
  "paycardAmount":null,
  "woodAmount":null,
  "woodAmountBack":null,
  "woodDeposit":null,
  "informationFee":null,
  "informationFeeStatus":null,
  "insuranceFee":null,
  "otherFee":null,
  "apportionType":null,
  "wbRemark":""
  },
  {
  "outId":"WB211228002",
  "crirId":167,
  "strtNm":null,
  "destNm":null,
  "destId":null,
  "payStatus":0,
  "oilPayStatus":null,
  "payCardCount":"0",
  "outFee":1.0000000000,
  "depositFee":0E-10,
  "outDt":"2021-12-28",
  "type":"外包",
  "remark":"",
  "tranFlag":null,
  "strt":"杭州市",
  "trafer":null,
  "bourn":null,
  "carrierId":null,
  "carrierNm":null,
  "carrierFee":null,
  "carrierPayTp":null,
  "status":"已外包",
  "coId":null,
  "createDt":null,
  "createUsr":null,
  "lastDt":"2021-12-28",
  "lastUsr":null,
  "state":null,
  "instQty":2,
  "instGrossWei":null,
  "instWei":2.0000000000,
  "instVol":2.0000000000,
  "expectDt":"2021-12-25",
  "dest":"温州市",
  "payTp":"月结",
  "crirNm":"通泰物流",
  "cnor":"杭州西子",
  "cnorCtcts":"高强",
  "cnorTel":"13675866678",
  "rcvrAddr":"天津市东丽区天津西子联合有限公司(东北门)航空路278号",
  "cgNm":"3层",
  "strtCtcts":"张家俊",
  "strtTel":"15497668342",
  "destCtcts":null,
  "destTel":null,
  "rcvr":"天津西子",
  "rcvrCtcts":"魏东旭",
  "rcvrTel":"18622724430",
  "schedulingSource":"调度",
  "parOutId":"0",
  "mergeBillStatus":"0",
  "carrierBillStatus":"0",
  "nums":null,
  "wayId":"TT-211228002",
  "billStatus":null,
  "accessCode":null,
  "wayBillEditStatus":"0",
  "deliveryDt":"2021-12-25",
  "cnorNbr":"",
  "transId":null,
  "payAnotherStatus":"未代付",
  "outPayTp":"回付",
  "collectFee":0E-10,
  "advance":null,
  "advancePaidAmount":null,
  "advanceUnpaidAmount":null,
  "advanceSettlementStatus":null,
  "remitFee":null,
  "remitFeePaidAmount":null,
  "remitFeeUnpaidAmount":null,
  "remitFeeSettlementStatus":null,
  "receiptFee":null,
  "receiptFeePaidAmount":null,
  "receiptFeeUnpaidAmount":null,
  "receiptFeeSettlementStatus":null,
  "payCardFee":0E-10,
  "paycardAmount":null,
  "woodAmount":null,
  "woodAmountBack":null,
  "woodDeposit":null,
  "informationFee":null,
  "informationFeeStatus":null,
  "insuranceFee":null,
  "otherFee":null,
  "apportionType":null,
  "wbRemark":""
  },
  {
  "outId":"WB211228001",
  "crirId":180,
  "strtNm":null,
  "destNm":null,
  "destId":null,
  "payStatus":0,
  "oilPayStatus":null,
  "payCardCount":"0",
  "outFee":231.0000000000,
  "depositFee":0E-10,
  "outDt":"2021-12-28",
  "type":"外包",
  "remark":"",
  "tranFlag":null,
  "strt":"嘉兴市",
  "trafer":null,
  "bourn":null,
  "carrierId":null,
  "carrierNm":null,
  "carrierFee":null,
  "carrierPayTp":null,
  "status":"已外包",
  "coId":null,
  "createDt":null,
  "createUsr":null,
  "lastDt":"2021-12-28",
  "lastUsr":null,
  "state":null,
  "instQty":2,
  "instGrossWei":null,
  "instWei":3.0000000000,
  "instVol":2.0000000000,
  "expectDt":"2021-12-24",
  "dest":"温州市",
  "payTp":"月结",
  "crirNm":"台州专线",
  "cnor":"欧文斯科宁111",
  "cnorCtcts":"方青亮",
  "cnorTel":"13867139235",
  "rcvrAddr":" 桐乡市凤鸣街道同福东路688号",
  "cgNm":"复合材料",
  "strtCtcts":"客服",
  "strtTel":"88362753",
  "destCtcts":null,
  "destTel":null,
  "rcvr":"桐乡恒力",
  "rcvrCtcts":"小吕 ",
  "rcvrTel":"13372338939",
  "schedulingSource":"调度",
  "parOutId":"0",
  "mergeBillStatus":"0",
  "carrierBillStatus":"0",
  "nums":null,
  "wayId":"TT-211228001",
  "billStatus":null,
  "accessCode":null,
  "wayBillEditStatus":"0",
  "deliveryDt":"2021-12-24",
  "cnorNbr":"",
  "transId":null,
  "payAnotherStatus":"未代付",
  "outPayTp":"回付",
  "collectFee":0E-10,
  "advance":null,
  "advancePaidAmount":null,
  "advanceUnpaidAmount":null,
  "advanceSettlementStatus":null,
  "remitFee":null,
  "remitFeePaidAmount":null,
  "remitFeeUnpaidAmount":null,
  "remitFeeSettlementStatus":null,
  "receiptFee":null,
  "receiptFeePaidAmount":null,
  "receiptFeeUnpaidAmount":null,
  "receiptFeeSettlementStatus":null,
  "payCardFee":0E-10,
  "paycardAmount":null,
  "woodAmount":null,
  "woodAmountBack":null,
  "woodDeposit":null,
  "informationFee":null,
  "informationFeeStatus":null,
  "insuranceFee":null,
  "otherFee":null,
  "apportionType":null,
  "wbRemark":""
  },
  {
  "outId":"WB211228006",
  "crirId":166,
  "strtNm":null,
  "destNm":null,
  "destId":null,
  "payStatus":0,
  "oilPayStatus":null,
  "payCardCount":"0",
  "outFee":60.0000000000,
  "depositFee":0E-10,
  "outDt":"2021-12-28",
  "type":"外包",
  "remark":"",
  "tranFlag":null,
  "strt":"余杭区",
  "trafer":null,
  "bourn":null,
  "carrierId":null,
  "carrierNm":null,
  "carrierFee":null,
  "carrierPayTp":null,
  "status":"已外包",
  "coId":null,
  "createDt":null,
  "createUsr":null,
  "lastDt":"2021-12-28",
  "lastUsr":null,
  "state":null,
  "instQty":1,
  "instGrossWei":null,
  "instWei":1.0000000000,
  "instVol":1.0000000000,
  "expectDt":"2021-12-28",
  "dest":"安陆市",
  "payTp":"多笔付",
  "crirNm":"杭州一运物流",
  "cnor":"欧文斯科宁111",
  "cnorCtcts":"方青亮",
  "cnorTel":"13867139235",
  "rcvrAddr":"上海市浦东新区金亮路57号，上海燕锦物流有限公司",
  "cgNm":"复合材料",
  "strtCtcts":"联系人",
  "strtTel":"18667005",
  "destCtcts":null,
  "destTel":null,
  "rcvr":"OC(KOREA)",
  "rcvrCtcts":"赵经理",
  "rcvrTel":"13681820609",
  "schedulingSource":"调度",
  "parOutId":"0",
  "mergeBillStatus":"0",
  "carrierBillStatus":"2",
  "nums":null,
  "wayId":"TT-211228005",
  "billStatus":null,
  "accessCode":null,
  "wayBillEditStatus":"0",
  "deliveryDt":"2021-12-28",
  "cnorNbr":"",
  "transId":null,
  "payAnotherStatus":"未代付",
  "outPayTp":"多笔付",
  "collectFee":25.0000000000,
  "advance":null,
  "advancePaidAmount":null,
  "advanceUnpaidAmount":null,
  "advanceSettlementStatus":null,
  "remitFee":null,
  "remitFeePaidAmount":null,
  "remitFeeUnpaidAmount":null,
  "remitFeeSettlementStatus":null,
  "receiptFee":null,
  "receiptFeePaidAmount":null,
  "receiptFeeUnpaidAmount":null,
  "receiptFeeSettlementStatus":null,
  "payCardFee":0E-10,
  "paycardAmount":null,
  "woodAmount":null,
  "woodAmountBack":null,
  "woodDeposit":null,
  "informationFee":null,
  "informationFeeStatus":null,
  "insuranceFee":null,
  "otherFee":null,
  "apportionType":null,
  "wbRemark":""
  },
  {
  "outId":"WB211225001",
  "crirId":179,
  "strtNm":null,
  "destNm":null,
  "destId":null,
  "payStatus":0,
  "oilPayStatus":null,
  "payCardCount":"0",
  "outFee":16000.0000000000,
  "depositFee":1000.0000000000,
  "outDt":"2021-12-25",
  "type":"外包",
  "remark":"测试费用",
  "tranFlag":null,
  "strt":"嘉兴市",
  "trafer":null,
  "bourn":null,
  "carrierId":null,
  "carrierNm":null,
  "carrierFee":null,
  "carrierPayTp":null,
  "status":"已外包",
  "coId":null,
  "createDt":null,
  "createUsr":null,
  "lastDt":"2021-12-25",
  "lastUsr":null,
  "state":null,
  "instQty":1,
  "instGrossWei":null,
  "instWei":1.0000000000,
  "instVol":1.0000000000,
  "expectDt":"2021-12-25",
  "dest":"余杭区",
  "payTp":"月结",
  "crirNm":"项目三中肯物流",
  "cnor":"欧文斯科宁111",
  "cnorCtcts":"方青亮",
  "cnorTel":"13867139235",
  "rcvrAddr":"11",
  "cgNm":"复合材料",
  "strtCtcts":"客服",
  "strtTel":"88142043",
  "destCtcts":null,
  "destTel":null,
  "rcvr":"浦东进仓",
  "rcvrCtcts":"11",
  "rcvrTel":"11",
  "schedulingSource":"调度",
  "parOutId":"0",
  "mergeBillStatus":"0",
  "carrierBillStatus":"1",
  "nums":null,
  "wayId":"TT-211225003",
  "billStatus":null,
  "accessCode":null,
  "wayBillEditStatus":"0",
  "deliveryDt":"2021-12-25",
  "cnorNbr":"",
  "transId":null,
  "payAnotherStatus":"未代付",
  "outPayTp":"多笔付",
  "collectFee":0E-10,
  "advance":null,
  "advancePaidAmount":null,
  "advanceUnpaidAmount":null,
  "advanceSettlementStatus":null,
  "remitFee":null,
  "remitFeePaidAmount":null,
  "remitFeeUnpaidAmount":null,
  "remitFeeSettlementStatus":null,
  "receiptFee":null,
  "receiptFeePaidAmount":null,
  "receiptFeeUnpaidAmount":null,
  "receiptFeeSettlementStatus":null,
  "payCardFee":4000.0000000000,
  "paycardAmount":null,
  "woodAmount":null,
  "woodAmountBack":null,
  "woodDeposit":null,
  "informationFee":null,
  "informationFeeStatus":null,
  "insuranceFee":null,
  "otherFee":null,
  "apportionType":null,
  "wbRemark":""
  },
  {
  "outId":"WB211202078",
  "crirId":190,
  "strtNm":null,
  "destNm":null,
  "destId":null,
  "payStatus":0,
  "oilPayStatus":null,
  "payCardCount":"0",
  "outFee":1053.0000000000,
  "depositFee":0E-10,
  "outDt":"2021-12-24",
  "type":"外包",
  "remark":"",
  "tranFlag":null,
  "strt":"临安区",
  "trafer":null,
  "bourn":null,
  "carrierId":null,
  "carrierNm":null,
  "carrierFee":null,
  "carrierPayTp":null,
  "status":"已外包",
  "coId":null,
  "createDt":null,
  "createUsr":null,
  "lastDt":"2021-12-02",
  "lastUsr":null,
  "state":null,
  "instQty":7,
  "instGrossWei":null,
  "instWei":2.3500000000,
  "instVol":0E-10,
  "expectDt":"2021-11-27",
  "dest":"佛山市",
  "payTp":"月结",
  "crirNm":"佳泰广东线",
  "cnor":"西子富沃德",
  "cnorCtcts":"高强",
  "cnorTel":"13675866678",
  "rcvrAddr":"广东省佛山市三水工业园大塘园A区61-2号",
  "cgNm":"主机",
  "strtCtcts":"赵乾隆",
  "strtTel":"18906533865",
  "destCtcts":null,
  "destTel":null,
  "rcvr":"西子西奥电梯有限公司",
  "rcvrCtcts":"沈秋福",
  "rcvrTel":"17779252952",
  "schedulingSource":"调度",
  "parOutId":"0",
  "mergeBillStatus":"0",
  "carrierBillStatus":"0",
  "nums":null,
  "wayId":"TT-211202143",
  "billStatus":null,
  "accessCode":null,
  "wayBillEditStatus":"0",
  "deliveryDt":"2021-11-24",
  "cnorNbr":"",
  "transId":null,
  "payAnotherStatus":"未代付",
  "outPayTp":"回付",
  "collectFee":0E-10,
  "advance":null,
  "advancePaidAmount":null,
  "advanceUnpaidAmount":null,
  "advanceSettlementStatus":null,
  "remitFee":null,
  "remitFeePaidAmount":null,
  "remitFeeUnpaidAmount":null,
  "remitFeeSettlementStatus":null,
  "receiptFee":null,
  "receiptFeePaidAmount":null,
  "receiptFeeUnpaidAmount":null,
  "receiptFeeSettlementStatus":null,
  "payCardFee":0E-10,
  "paycardAmount":0,
  "woodAmount":null,
  "woodAmountBack":null,
  "woodDeposit":null,
  "informationFee":null,
  "informationFeeStatus":null,
  "insuranceFee":null,
  "otherFee":null,
  "apportionType":null,
  "wbRemark":""
  },
  {
  "outId":"WB211202077",
  "crirId":190,
  "strtNm":null,
  "destNm":null,
  "destId":null,
  "payStatus":0,
  "oilPayStatus":null,
  "payCardCount":"0",
  "outFee":1369.0000000000,
  "depositFee":0E-10,
  "outDt":"2021-12-24",
  "type":"外包",
  "remark":"",
  "tranFlag":null,
  "strt":"临安区",
  "trafer":null,
  "bourn":null,
  "carrierId":null,
  "carrierNm":null,
  "carrierFee":null,
  "carrierPayTp":null,
  "status":"已外包",
  "coId":null,
  "createDt":null,
  "createUsr":null,
  "lastDt":"2021-12-02",
  "lastUsr":null,
  "state":null,
  "instQty":7,
  "instGrossWei":null,
  "instWei":2.8170000000,
  "instVol":0E-10,
  "expectDt":"2021-11-28",
  "dest":"惠州市",
  "payTp":"月结",
  "crirNm":"佳泰广东线",
  "cnor":"西子富沃德",
  "cnorCtcts":"高强",
  "cnorTel":"13675866678",
  "rcvrAddr":"惠州市博罗县石湾镇鸾岗村卢屋小组大牛路",
  "cgNm":"主机",
  "strtCtcts":"赵乾隆",
  "strtTel":"18906533865",
  "destCtcts":null,
  "destTel":null,
  "rcvr":"深圳市港日电梯有限公司惠州分公司",
  "rcvrCtcts":"董启玉",
  "rcvrTel":"13751039865",
  "schedulingSource":"调度",
  "parOutId":"0",
  "mergeBillStatus":"0",
  "carrierBillStatus":"0",
  "nums":null,
  "wayId":"TT-211202142",
  "billStatus":null,
  "accessCode":null,
  "wayBillEditStatus":"0",
  "deliveryDt":"2021-11-24",
  "cnorNbr":"",
  "transId":null,
  "payAnotherStatus":"未代付",
  "outPayTp":"回付",
  "collectFee":0E-10,
  "advance":null,
  "advancePaidAmount":null,
  "advanceUnpaidAmount":null,
  "advanceSettlementStatus":null,
  "remitFee":null,
  "remitFeePaidAmount":null,
  "remitFeeUnpaidAmount":null,
  "remitFeeSettlementStatus":null,
  "receiptFee":null,
  "receiptFeePaidAmount":null,
  "receiptFeeUnpaidAmount":null,
  "receiptFeeSettlementStatus":null,
  "payCardFee":0E-10,
  "paycardAmount":0,
  "woodAmount":null,
  "woodAmountBack":null,
  "woodDeposit":null,
  "informationFee":null,
  "informationFeeStatus":null,
  "insuranceFee":null,
  "otherFee":null,
  "apportionType":null,
  "wbRemark":""
  },
  {
  "outId":"WB211202076",
  "crirId":190,
  "strtNm":null,
  "destNm":null,
  "destId":null,
  "payStatus":0,
  "oilPayStatus":null,
  "payCardCount":"0",
  "outFee":792.0000000000,
  "depositFee":0E-10,
  "outDt":"2021-12-24",
  "type":"外包",
  "remark":"",
  "tranFlag":null,
  "strt":"临安区",
  "trafer":null,
  "bourn":null,
  "carrierId":null,
  "carrierNm":null,
  "carrierFee":null,
  "carrierPayTp":null,
  "status":"已外包",
  "coId":null,
  "createDt":null,
  "createUsr":null,
  "lastDt":"2021-12-02",
  "lastUsr":null,
  "state":null,
  "instQty":3,
  "instGrossWei":null,
  "instWei":1.6300000000,
  "instVol":0E-10,
  "expectDt":"2021-11-28",
  "dest":"惠州市",
  "payTp":"月结",
  "crirNm":"佳泰广东线",
  "cnor":"西子富沃德",
  "cnorCtcts":"高强",
  "cnorTel":"13675866678",
  "rcvrAddr":"惠州市惠阳区镇隆青草窝",
  "cgNm":"主机",
  "strtCtcts":"赵乾隆",
  "strtTel":"18906533865",
  "destCtcts":null,
  "destTel":null,
  "rcvr":"惠州市远大电梯有限公司",
  "rcvrCtcts":"龚方东",
  "rcvrTel":"13927341668/0752-3538648",
  "schedulingSource":"调度",
  "parOutId":"0",
  "mergeBillStatus":"0",
  "carrierBillStatus":"0",
  "nums":null,
  "wayId":"TT-211202141",
  "billStatus":null,
  "accessCode":null,
  "wayBillEditStatus":"0",
  "deliveryDt":"2021-11-24",
  "cnorNbr":"",
  "transId":null,
  "payAnotherStatus":"未代付",
  "outPayTp":"回付",
  "collectFee":0E-10,
  "advance":null,
  "advancePaidAmount":null,
  "advanceUnpaidAmount":null,
  "advanceSettlementStatus":null,
  "remitFee":null,
  "remitFeePaidAmount":null,
  "remitFeeUnpaidAmount":null,
  "remitFeeSettlementStatus":null,
  "receiptFee":null,
  "receiptFeePaidAmount":null,
  "receiptFeeUnpaidAmount":null,
  "receiptFeeSettlementStatus":null,
  "payCardFee":0E-10,
  "paycardAmount":0,
  "woodAmount":null,
  "woodAmountBack":null,
  "woodDeposit":null,
  "informationFee":null,
  "informationFeeStatus":null,
  "insuranceFee":null,
  "otherFee":null,
  "apportionType":null,
  "wbRemark":""
  },
  {
  "outId":"WB211202075",
  "crirId":190,
  "strtNm":null,
  "destNm":null,
  "destId":null,
  "payStatus":0,
  "oilPayStatus":null,
  "payCardCount":"0",
  "outFee":1492.0000000000,
  "depositFee":0E-10,
  "outDt":"2021-12-24",
  "type":"外包",
  "remark":"",
  "tranFlag":null,
  "strt":"临安区",
  "trafer":null,
  "bourn":null,
  "carrierId":null,
  "carrierNm":null,
  "carrierFee":null,
  "carrierPayTp":null,
  "status":"已外包",
  "coId":null,
  "createDt":null,
  "createUsr":null,
  "lastDt":"2021-12-02",
  "lastUsr":null,
  "state":null,
  "instQty":5,
  "instGrossWei":null,
  "instWei":3.4530000000,
  "instVol":0E-10,
  "expectDt":"2021-11-27",
  "dest":"广州市",
  "payTp":"月结",
  "crirNm":"佳泰广东线",
  "cnor":"西子富沃德",
  "cnorCtcts":"高强",
  "cnorTel":"13675866678",
  "rcvrAddr":"广州市番禺区是石楼镇国贸大道广日工业园旁边的从工业园西门进入,绕广日物流仓库一圈,到19号门的指定位置上停车卸货",
  "cgNm":"主机",
  "strtCtcts":"赵乾隆",
  "strtTel":"18906533865",
  "destCtcts":null,
  "destTel":null,
  "rcvr":"广州广日电梯工业有限公司",
  "rcvrCtcts":"李杰/赖金生",
  "rcvrTel":"18100205235/18718405278",
  "schedulingSource":"调度",
  "parOutId":"0",
  "mergeBillStatus":"0",
  "carrierBillStatus":"0",
  "nums":null,
  "wayId":"TT-211202140",
  "billStatus":null,
  "accessCode":null,
  "wayBillEditStatus":"0",
  "deliveryDt":"2021-11-24",
  "cnorNbr":"",
  "transId":null,
  "payAnotherStatus":"未代付",
  "outPayTp":"回付",
  "collectFee":0E-10,
  "advance":null,
  "advancePaidAmount":null,
  "advanceUnpaidAmount":null,
  "advanceSettlementStatus":null,
  "remitFee":null,
  "remitFeePaidAmount":null,
  "remitFeeUnpaidAmount":null,
  "remitFeeSettlementStatus":null,
  "receiptFee":null,
  "receiptFeePaidAmount":null,
  "receiptFeeUnpaidAmount":null,
  "receiptFeeSettlementStatus":null,
  "payCardFee":0E-10,
  "paycardAmount":0,
  "woodAmount":null,
  "woodAmountBack":null,
  "woodDeposit":null,
  "informationFee":null,
  "informationFeeStatus":null,
  "insuranceFee":null,
  "otherFee":null,
  "apportionType":null,
  "wbRemark":""
  },
  {
  "outId":"WB211202074",
  "crirId":190,
  "strtNm":null,
  "destNm":null,
  "destId":null,
  "payStatus":0,
  "oilPayStatus":null,
  "payCardCount":"0",
  "outFee":164.0000000000,
  "depositFee":0E-10,
  "outDt":"2021-12-24",
  "type":"外包",
  "remark":"",
  "tranFlag":null,
  "strt":"临安区",
  "trafer":null,
  "bourn":null,
  "carrierId":null,
  "carrierNm":null,
  "carrierFee":null,
  "carrierPayTp":null,
  "status":"已外包",
  "coId":null,
  "createDt":null,
  "createUsr":null,
  "lastDt":"2021-12-02",
  "lastUsr":null,
  "state":null,
  "instQty":1,
  "instGrossWei":null,
  "instWei":0.3600000000,
  "instVol":0E-10,
  "expectDt":"2021-11-28",
  "dest":"东莞市",
  "payTp":"月结",
  "crirNm":"佳泰广东线",
  "cnor":"西子富沃德",
  "cnorCtcts":"高强",
  "cnorTel":"13675866678",
  "rcvrAddr":"广东省东莞市塘厦镇环村路西",
  "cgNm":"主机",
  "strtCtcts":"赵乾隆",
  "strtTel":"18906533865",
  "destCtcts":null,
  "destTel":null,
  "rcvr":"广东长城电梯有限公司",
  "rcvrCtcts":"李子坤",
  "rcvrTel":"18033496223",
  "schedulingSource":"调度",
  "parOutId":"0",
  "mergeBillStatus":"0",
  "carrierBillStatus":"0",
  "nums":null,
  "wayId":"TT-211202139",
  "billStatus":null,
  "accessCode":null,
  "wayBillEditStatus":"0",
  "deliveryDt":"2021-11-24",
  "cnorNbr":"",
  "transId":null,
  "payAnotherStatus":"未代付",
  "outPayTp":"回付",
  "collectFee":0E-10,
  "advance":null,
  "advancePaidAmount":null,
  "advanceUnpaidAmount":null,
  "advanceSettlementStatus":null,
  "remitFee":null,
  "remitFeePaidAmount":null,
  "remitFeeUnpaidAmount":null,
  "remitFeeSettlementStatus":null,
  "receiptFee":null,
  "receiptFeePaidAmount":null,
  "receiptFeeUnpaidAmount":null,
  "receiptFeeSettlementStatus":null,
  "payCardFee":0E-10,
  "paycardAmount":0,
  "woodAmount":null,
  "woodAmountBack":null,
  "woodDeposit":null,
  "informationFee":null,
  "informationFeeStatus":null,
  "insuranceFee":null,
  "otherFee":null,
  "apportionType":null,
  "wbRemark":""
  },
  {
  "outId":"WB211202073",
  "crirId":190,
  "strtNm":null,
  "destNm":null,
  "destId":null,
  "payStatus":0,
  "oilPayStatus":null,
  "payCardCount":"0",
  "outFee":454.0000000000,
  "depositFee":0E-10,
  "outDt":"2021-12-24",
  "type":"外包",
  "remark":"",
  "tranFlag":null,
  "strt":"临安区",
  "trafer":null,
  "bourn":null,
  "carrierId":null,
  "carrierNm":null,
  "carrierFee":null,
  "carrierPayTp":null,
  "status":"已外包",
  "coId":null,
  "createDt":null,
  "createUsr":null,
  "lastDt":"2021-12-02",
  "lastUsr":null,
  "state":null,
  "instQty":3,
  "instGrossWei":null,
  "instWei":0.9950000000,
  "instVol":0E-10,
  "expectDt":"2021-11-28",
  "dest":"东莞市",
  "payTp":"月结",
  "crirNm":"佳泰广东线",
  "cnor":"西子富沃德",
  "cnorCtcts":"高强",
  "cnorTel":"13675866678",
  "rcvrAddr":"广东省东莞市察步镇井巷村建新路79号",
  "cgNm":"主机",
  "strtCtcts":"赵乾隆",
  "strtTel":"18906533865",
  "destCtcts":null,
  "destTel":null,
  "rcvr":"广东日芝电梯有限公司",
  "rcvrCtcts":"钟仲豪",
  "rcvrTel":"13537202173",
  "schedulingSource":"调度",
  "parOutId":"0",
  "mergeBillStatus":"0",
  "carrierBillStatus":"0",
  "nums":null,
  "wayId":"TT-211202138",
  "billStatus":null,
  "accessCode":null,
  "wayBillEditStatus":"0",
  "deliveryDt":"2021-11-24",
  "cnorNbr":"",
  "transId":null,
  "payAnotherStatus":"未代付",
  "outPayTp":"回付",
  "collectFee":0E-10,
  "advance":null,
  "advancePaidAmount":null,
  "advanceUnpaidAmount":null,
  "advanceSettlementStatus":null,
  "remitFee":null,
  "remitFeePaidAmount":null,
  "remitFeeUnpaidAmount":null,
  "remitFeeSettlementStatus":null,
  "receiptFee":null,
  "receiptFeePaidAmount":null,
  "receiptFeeUnpaidAmount":null,
  "receiptFeeSettlementStatus":null,
  "payCardFee":0E-10,
  "paycardAmount":0,
  "woodAmount":null,
  "woodAmountBack":null,
  "woodDeposit":null,
  "informationFee":null,
  "informationFeeStatus":null,
  "insuranceFee":null,
  "otherFee":null,
  "apportionType":null,
  "wbRemark":""
  },
  {
  "outId":"WB211202072",
  "crirId":190,
  "strtNm":null,
  "destNm":null,
  "destId":null,
  "payStatus":0,
  "oilPayStatus":null,
  "payCardCount":"0",
  "outFee":323.0000000000,
  "depositFee":0E-10,
  "outDt":"2021-12-24",
  "type":"外包",
  "remark":"",
  "tranFlag":null,
  "strt":"临安区",
  "trafer":null,
  "bourn":null,
  "carrierId":null,
  "carrierNm":null,
  "carrierFee":null,
  "carrierPayTp":null,
  "status":"已外包",
  "coId":null,
  "createDt":null,
  "createUsr":null,
  "lastDt":"2021-12-02",
  "lastUsr":null,
  "state":null,
  "instQty":2,
  "instGrossWei":null,
  "instWei":0.7200000000,
  "instVol":0E-10,
  "expectDt":"2021-11-27",
  "dest":"佛山市",
  "payTp":"月结",
  "crirNm":"佳泰广东线",
  "cnor":"西子富沃德",
  "cnorCtcts":"高强",
  "cnorTel":"13675866678",
  "rcvrAddr":"广东省佛山市三水区乐平镇三水工业园D区67号",
  "cgNm":"主机",
  "strtCtcts":"赵乾隆",
  "strtTel":"18906533865",
  "destCtcts":null,
  "destTel":null,
  "rcvr":"广东联合富士电梯有限公司",
  "rcvrCtcts":"黎燕敏",
  "rcvrTel":"15986124489",
  "schedulingSource":"调度",
  "parOutId":"0",
  "mergeBillStatus":"0",
  "carrierBillStatus":"0",
  "nums":null,
  "wayId":"TT-211202137",
  "billStatus":null,
  "accessCode":null,
  "wayBillEditStatus":"0",
  "deliveryDt":"2021-11-24",
  "cnorNbr":"",
  "transId":null,
  "payAnotherStatus":"未代付",
  "outPayTp":"回付",
  "collectFee":0E-10,
  "advance":null,
  "advancePaidAmount":null,
  "advanceUnpaidAmount":null,
  "advanceSettlementStatus":null,
  "remitFee":null,
  "remitFeePaidAmount":null,
  "remitFeeUnpaidAmount":null,
  "remitFeeSettlementStatus":null,
  "receiptFee":null,
  "receiptFeePaidAmount":null,
  "receiptFeeUnpaidAmount":null,
  "receiptFeeSettlementStatus":null,
  "payCardFee":0E-10,
  "paycardAmount":0,
  "woodAmount":null,
  "woodAmountBack":null,
  "woodDeposit":null,
  "informationFee":null,
  "informationFeeStatus":null,
  "insuranceFee":null,
  "otherFee":null,
  "apportionType":null,
  "wbRemark":""
  },
  {
  "outId":"WB211202071",
  "crirId":190,
  "strtNm":null,
  "destNm":null,
  "destId":null,
  "payStatus":0,
  "oilPayStatus":null,
  "payCardCount":"0",
  "outFee":774.0000000000,
  "depositFee":0E-10,
  "outDt":"2021-12-24",
  "type":"外包",
  "remark":"",
  "tranFlag":null,
  "strt":"临安区",
  "trafer":null,
  "bourn":null,
  "carrierId":null,
  "carrierNm":null,
  "carrierFee":null,
  "carrierPayTp":null,
  "status":"已外包",
  "coId":null,
  "createDt":null,
  "createUsr":null,
  "lastDt":"2021-12-02",
  "lastUsr":null,
  "state":null,
  "instQty":3,
  "instGrossWei":null,
  "instWei":1.6300000000,
  "instVol":0E-10,
  "expectDt":"2021-11-28",
  "dest":"中山市",
  "payTp":"月结",
  "crirNm":"佳泰广东线",
  "cnor":"西子富沃德",
  "cnorCtcts":"高强",
  "cnorTel":"13675866678",
  "rcvrAddr":"广东省中山市南朗镇华南现代中医药城于意路8号",
  "cgNm":"主机",
  "strtCtcts":"赵乾隆",
  "strtTel":"18906533865",
  "destCtcts":null,
  "destTel":null,
  "rcvr":"广东康力电梯有限公司",
  "rcvrCtcts":"徐路路",
  "rcvrTel":"19928003068",
  "schedulingSource":"调度",
  "parOutId":"0",
  "mergeBillStatus":"0",
  "carrierBillStatus":"0",
  "nums":null,
  "wayId":"TT-211202136",
  "billStatus":null,
  "accessCode":null,
  "wayBillEditStatus":"0",
  "deliveryDt":"2021-11-24",
  "cnorNbr":"",
  "transId":null,
  "payAnotherStatus":"未代付",
  "outPayTp":"回付",
  "collectFee":0E-10,
  "advance":null,
  "advancePaidAmount":null,
  "advanceUnpaidAmount":null,
  "advanceSettlementStatus":null,
  "remitFee":null,
  "remitFeePaidAmount":null,
  "remitFeeUnpaidAmount":null,
  "remitFeeSettlementStatus":null,
  "receiptFee":null,
  "receiptFeePaidAmount":null,
  "receiptFeeUnpaidAmount":null,
  "receiptFeeSettlementStatus":null,
  "payCardFee":0E-10,
  "paycardAmount":0,
  "woodAmount":null,
  "woodAmountBack":null,
  "woodDeposit":null,
  "informationFee":null,
  "informationFeeStatus":null,
  "insuranceFee":null,
  "otherFee":null,
  "apportionType":null,
  "wbRemark":""
  },
  {
  "outId":"WB211202070",
  "crirId":190,
  "strtNm":null,
  "destNm":null,
  "destId":null,
  "payStatus":0,
  "oilPayStatus":null,
  "payCardCount":"0",
  "outFee":323.0000000000,
  "depositFee":0E-10,
  "outDt":"2021-12-24",
  "type":"外包",
  "remark":"",
  "tranFlag":null,
  "strt":"临安区",
  "trafer":null,
  "bourn":null,
  "carrierId":null,
  "carrierNm":null,
  "carrierFee":null,
  "carrierPayTp":null,
  "status":"已外包",
  "coId":null,
  "createDt":null,
  "createUsr":null,
  "lastDt":"2021-12-02",
  "lastUsr":null,
  "state":null,
  "instQty":2,
  "instGrossWei":null,
  "instWei":0.7200000000,
  "instVol":0E-10,
  "expectDt":"2021-11-27",
  "dest":"佛山市",
  "payTp":"月结",
  "crirNm":"佳泰广东线",
  "cnor":"西子富沃德",
  "cnorCtcts":"高强",
  "cnorTel":"13675866678",
  "rcvrAddr":"广东省佛山市三水区芦苞工业园C区2-5号",
  "cgNm":"主机",
  "strtCtcts":"赵乾隆",
  "strtTel":"18906533865",
  "destCtcts":null,
  "destTel":null,
  "rcvr":"广东升达电梯有限公司",
  "rcvrCtcts":"卢伟清",
  "rcvrTel":"15816916992",
  "schedulingSource":"调度",
  "parOutId":"0",
  "mergeBillStatus":"0",
  "carrierBillStatus":"0",
  "nums":null,
  "wayId":"TT-211202135",
  "billStatus":null,
  "accessCode":null,
  "wayBillEditStatus":"0",
  "deliveryDt":"2021-11-24",
  "cnorNbr":"",
  "transId":null,
  "payAnotherStatus":"未代付",
  "outPayTp":"回付",
  "collectFee":0E-10,
  "advance":null,
  "advancePaidAmount":null,
  "advanceUnpaidAmount":null,
  "advanceSettlementStatus":null,
  "remitFee":null,
  "remitFeePaidAmount":null,
  "remitFeeUnpaidAmount":null,
  "remitFeeSettlementStatus":null,
  "receiptFee":null,
  "receiptFeePaidAmount":null,
  "receiptFeeUnpaidAmount":null,
  "receiptFeeSettlementStatus":null,
  "payCardFee":0E-10,
  "paycardAmount":0,
  "woodAmount":null,
  "woodAmountBack":null,
  "woodDeposit":null,
  "informationFee":null,
  "informationFeeStatus":null,
  "insuranceFee":null,
  "otherFee":null,
  "apportionType":null,
  "wbRemark":""
  },
  {
  "outId":"WBA211223001",
  "crirId":168,
  "strtNm":null,
  "destNm":null,
  "destId":null,
  "payStatus":0,
  "oilPayStatus":null,
  "payCardCount":"0",
  "outFee":100.0000000000,
  "depositFee":0E-10,
  "outDt":"2021-12-23",
  "type":"短驳",
  "remark":null,
  "tranFlag":null,
  "strt":"嘉兴市",
  "trafer":null,
  "bourn":null,
  "carrierId":null,
  "carrierNm":null,
  "carrierFee":null,
  "carrierPayTp":null,
  "status":"已外包",
  "coId":null,
  "createDt":null,
  "createUsr":null,
  "lastDt":"2021-12-23",
  "lastUsr":null,
  "state":null,
  "instQty":2,
  "instGrossWei":null,
  "instWei":2.0000000000,
  "instVol":0E-10,
  "expectDt":"2021-12-23",
  "dest":"安陆市",
  "payTp":"月结",
  "crirNm":"德志物流",
  "cnor":"欧文斯科宁111",
  "cnorCtcts":"方青亮",
  "cnorTel":"13867139235",
  "rcvrAddr":"上海市浦东新区高桥镇巴斯夫(中国)有限公司巴斯夫新材料有限公司上海市浦东新区江心沙路在地图中查看9号",
  "cgNm":"复合材料",
  "strtCtcts":"张森",
  "strtTel":"13185539999",
  "destCtcts":null,
  "destTel":null,
  "rcvr":"浦东进仓",
  "rcvrCtcts":"黄建峰 ",
  "rcvrTel":"13501629011",
  "schedulingSource":"调度",
  "parOutId":"0",
  "mergeBillStatus":"0",
  "carrierBillStatus":"0",
  "nums":null,
  "wayId":"TT-211223002",
  "billStatus":null,
  "accessCode":null,
  "wayBillEditStatus":"0",
  "deliveryDt":"2021-12-23",
  "cnorNbr":"",
  "transId":"DB211223001",
  "payAnotherStatus":"未代付",
  "outPayTp":"回付",
  "collectFee":0E-10,
  "advance":null,
  "advancePaidAmount":null,
  "advanceUnpaidAmount":null,
  "advanceSettlementStatus":null,
  "remitFee":null,
  "remitFeePaidAmount":null,
  "remitFeeUnpaidAmount":null,
  "remitFeeSettlementStatus":null,
  "receiptFee":null,
  "receiptFeePaidAmount":null,
  "receiptFeeUnpaidAmount":null,
  "receiptFeeSettlementStatus":null,
  "payCardFee":0E-10,
  "paycardAmount":0,
  "woodAmount":null,
  "woodAmountBack":null,
  "woodDeposit":null,
  "informationFee":null,
  "informationFeeStatus":null,
  "insuranceFee":null,
  "otherFee":null,
  "apportionType":null,
  "wbRemark":""
  },
  {
  "outId":"WB211223001",
  "crirId":179,
  "strtNm":null,
  "destNm":null,
  "destId":null,
  "payStatus":0,
  "oilPayStatus":null,
  "payCardCount":"0",
  "outFee":2000.0000000000,
  "depositFee":0E-10,
  "outDt":"2021-12-23",
  "type":"外包",
  "remark":"",
  "tranFlag":null,
  "strt":"嘉兴市",
  "trafer":null,
  "bourn":null,
  "carrierId":null,
  "carrierNm":null,
  "carrierFee":null,
  "carrierPayTp":null,
  "status":"已外包",
  "coId":null,
  "createDt":null,
  "createUsr":null,
  "lastDt":"2021-12-23",
  "lastUsr":null,
  "state":null,
  "instQty":2,
  "instGrossWei":null,
  "instWei":2.0000000000,
  "instVol":2.0000000000,
  "expectDt":"2021-12-22",
  "dest":"余杭区",
  "payTp":"月结",
  "crirNm":"项目三中肯物流",
  "cnor":"欧文斯科宁111",
  "cnorCtcts":"方青亮",
  "cnorTel":"13867139235",
  "rcvrAddr":"上海市浦东新区金亮路57号，上海燕锦物流有限公司",
  "cgNm":"复合材料",
  "strtCtcts":"客服",
  "strtTel":"88142043",
  "destCtcts":null,
  "destTel":null,
  "rcvr":"OC(KOREA)",
  "rcvrCtcts":"赵经理",
  "rcvrTel":"13681820609",
  "schedulingSource":"调度",
  "parOutId":"0",
  "mergeBillStatus":"0",
  "carrierBillStatus":"0",
  "nums":null,
  "wayId":"TT-211223001",
  "billStatus":null,
  "accessCode":null,
  "wayBillEditStatus":"0",
  "deliveryDt":"2021-12-22",
  "cnorNbr":"",
  "transId":null,
  "payAnotherStatus":"未代付",
  "outPayTp":"回付",
  "collectFee":0E-10,
  "advance":null,
  "advancePaidAmount":null,
  "advanceUnpaidAmount":null,
  "advanceSettlementStatus":null,
  "remitFee":null,
  "remitFeePaidAmount":null,
  "remitFeeUnpaidAmount":null,
  "remitFeeSettlementStatus":null,
  "receiptFee":null,
  "receiptFeePaidAmount":null,
  "receiptFeeUnpaidAmount":null,
  "receiptFeeSettlementStatus":null,
  "payCardFee":0E-10,
  "paycardAmount":null,
  "woodAmount":null,
  "woodAmountBack":null,
  "woodDeposit":null,
  "informationFee":null,
  "informationFeeStatus":null,
  "insuranceFee":null,
  "otherFee":null,
  "apportionType":null,
  "wbRemark":""
  },
  {
  "outId":"WBA211223007",
  "crirId":167,
  "strtNm":null,
  "destNm":null,
  "destId":null,
  "payStatus":0,
  "oilPayStatus":null,
  "payCardCount":"0",
  "outFee":1.0000000000,
  "depositFee":0E-10,
  "outDt":"2021-12-23",
  "type":"短驳",
  "remark":"外包哈",
  "tranFlag":null,
  "strt":"杭州市",
  "trafer":null,
  "bourn":null,
  "carrierId":null,
  "carrierNm":null,
  "carrierFee":null,
  "carrierPayTp":null,
  "status":"已外包",
  "coId":null,
  "createDt":null,
  "createUsr":null,
  "lastDt":"2021-12-23",
  "lastUsr":null,
  "state":null,
  "instQty":1,
  "instGrossWei":null,
  "instWei":1.0000000000,
  "instVol":1.0000000000,
  "expectDt":"2021-12-23",
  "dest":"秦皇岛市",
  "payTp":"月结",
  "crirNm":"通泰物流",
  "cnor":"欧文斯科宁111",
  "cnorCtcts":"方青亮",
  "cnorTel":"13867139235",
  "rcvrAddr":"11",
  "cgNm":"复合材料",
  "strtCtcts":"张家俊",
  "strtTel":"15497668342",
  "destCtcts":null,
  "destTel":null,
  "rcvr":"OC(KOREA)",
  "rcvrCtcts":"11",
  "rcvrTel":"11",
  "schedulingSource":"调度",
  "parOutId":"0",
  "mergeBillStatus":"0",
  "carrierBillStatus":"0",
  "nums":null,
  "wayId":"TT-211223009",
  "billStatus":null,
  "accessCode":null,
  "wayBillEditStatus":"0",
  "deliveryDt":"2021-12-23",
  "cnorNbr":"",
  "transId":"DB211223008",
  "payAnotherStatus":"未代付",
  "outPayTp":null,
  "collectFee":0E-10,
  "advance":null,
  "advancePaidAmount":null,
  "advanceUnpaidAmount":null,
  "advanceSettlementStatus":null,
  "remitFee":null,
  "remitFeePaidAmount":null,
  "remitFeeUnpaidAmount":null,
  "remitFeeSettlementStatus":null,
  "receiptFee":null,
  "receiptFeePaidAmount":null,
  "receiptFeeUnpaidAmount":null,
  "receiptFeeSettlementStatus":null,
  "payCardFee":0E-10,
  "paycardAmount":0,
  "woodAmount":null,
  "woodAmountBack":null,
  "woodDeposit":null,
  "informationFee":null,
  "informationFeeStatus":null,
  "insuranceFee":null,
  "otherFee":null,
  "apportionType":null,
  "wbRemark":""
  }
  ]


export default {
  baseColumn,
  operateList,
  searchFields,
  tableFields,
  colSize,
  summary,
  total,
  items,
  fixedBtnList
}