<template>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="90px"
      size="mini"
      class="detail-form"
      :disabled="disabled"
    >
      <div class="header-line">
        <h2 class="header-title">订单信息</h2>
      </div>
      <el-row>
        <el-col :span="span">
          <el-form-item label="发货日期" prop="deliveryDt">
            <el-date-picker
              type="datetime"
              placeholder="选择日期"
              v-model="ruleForm.deliveryDt"
              clearable
              default-time="00:00:00"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 100%;"
              :picker-options="{
                disabledDate: disableDeliveryDt
              }"
            />
          </el-form-item>
        </el-col>
        <el-col :span="span">
          <el-form-item label="到货日期" prop="expectDt">
            <el-date-picker
              type="datetime"
              placeholder="选择日期"
              v-model="ruleForm.expectDt"
              clearable
              default-time="23:59:59"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 100%;"
              :picker-options="{
                disabledDate: disableExpectDt
              }"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="tip-container">
        <el-col :span="span">
          <el-form-item label="订单类型" prop="transTp">
            <el-select
              v-model="ruleForm.transTp"
              placeholder="请选择订单类型"
              style="width: 100%"
              clearable
            >
              <el-option
                v-for="item in orderTypeEnum"
                :key="item.value"
                :label="item.label" 
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="span">
          <el-form-item v-if="ruleForm.transTp === '2'" label="车型" prop="carTp">
            <el-select
              v-model="ruleForm.carTp"
              placeholder="请选择车型"
              style="width: 100%"
              clearable
            >
              <el-option
                v-for="item in carTypeEnum"
                :key="item.value"
                :label="item.label" 
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-tooltip class="tip" effect="dark" content="订单类型为整车时，车型必填" placement="right">
          <i class="el-icon-info"></i>
        </el-tooltip>
      </el-row>
      <!-- 发货信息 -->
      <div class="header-line">
        <h2 class="header-title">发货信息</h2>
      </div>
      <el-row>
        <el-col :span="span">
          <el-form-item label="姓名" prop="cnorCtctsIndex">
            <el-select
              v-model="ruleForm.cnorCtctsIndex"
              placeholder="请选择发货人姓名"
              style="width: 100%"
              @change="handleCnorCtctsChange"
              clearable
              filterable
            >
              <el-option
                v-for="(item, index) in consignerContactList"
                :key="item.cnorContactId"
                :label="item.cnorCtcts"
                :value="index"
              ></el-option>

            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="span">
          <el-form-item
            label="电话"
            prop="cnorCtctsIndex"
            :rules="[{ required: true, message: '请选择发货人手机号', trigger: 'change' }]"
          >
            <el-select
              v-model="ruleForm.cnorCtctsIndex"
              placeholder="请选择发货人手机号"
              style="width: 100%"
              @change="handleCnorTelChange"
              clearable
              filterable
            >
              <el-option
                v-for="(item, index) in consignerContactList"
                :key="item.cnorContactId"
                :label="item.cnorTel"
                :value="index"
              ></el-option>

            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <!-- <el-col :span="span">
          <el-form-item label="发货地址" prop="consignerCity">
            <el-cascader
              v-model="ruleForm.consignerCity"
              :options="regionData"
              placeholder="请选择发货城市"
              style="width: 100%"
              clearable
            ></el-cascader>
          </el-form-item>
        </el-col> -->
        <el-col>
          <el-form-item label="发货地址" prop="cnorAddrIndex">
            <el-select
              v-model="ruleForm.cnorAddrIndex"
              clearable
              type="text"
              size="small"
              class="input-cls"
              style="width: 100%"
              placeholder="请选择发货地址"
              filterable
              @change="handleCnorAddr"
            >
              <el-option
                v-for="(item, index) in cnorAddrList"
                :key="item.addrId"
                :label="item.cnorAddrNm"
                :value="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 收货信息 -->
      <div class="header-line">
        <h2 class="header-title">收货信息</h2>
      </div>
      <el-row>
        <el-form-item label="收货单位" prop="rcvrNmIndex">
          <el-select
            v-model="ruleForm.rcvrNmIndex"
            placeholder="请选择收货单位"
            style="width: 100%"
            filterable
            clearable
            @change="handleRcvrNmChange"
          >
            <el-option
              v-for="(item, index) in receiverList"
              :key="item.rcvrId + '' +  index"
              :label="item.rcvrNm"
              :value="index"
            ></el-option>

          </el-select>
        </el-form-item>
      </el-row>
      <el-row>
        <el-col :span="span">
          <el-form-item label="姓名" prop="rcvrContactIndex">
            <el-select
              v-model="ruleForm.rcvrContactIndex"
              placeholder="请选择收货人姓名"
              style="width: 100%"
              clearable
              filterable
              @change="handleRcvrCtctsChange"
            >
              <el-option
                v-for="(item, index) in receiverContactList"
                :key="item.rcvrContactId + '' +  index"
                :label="item.rcvrCtcts"
                :value="index"
              ></el-option>

            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="span">
          <el-form-item
            label="电话"
            prop="rcvrContactIndex"
            :rules="[{ required: true, message: '请选择收货人手机号', trigger: 'change' }]"
          >
            <el-select
              v-model="ruleForm.rcvrContactIndex"
              placeholder="请选择收货人手机号"
              style="width: 100%"
              @change="handleRcvrTelChange"
              clearable
              filterable
            >
              <el-option
                v-for="(item, index) in receiverContactList"
                :key="item.rcvrContactId + '' +  index"
                :label="item.rcvrTel"
                :value="index"
              ></el-option>

            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="span">
          <el-form-item label="收货城市" prop="receiverAddrIndex">
            <el-select
              v-model="ruleForm.receiverAddrIndex"
              placeholder="请选择收货城市"
              style="width: 100%"
              clearable
              filterable
              disabled
              @change="handleReceiverAddrChange"
            >
              <el-option
                v-for="(item, index) in receiverAddrList"
                :key="item.addrId + '' +  index"
                :label="item.aeName"
                :value="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="span">
          <el-form-item label="详细地址" prop="addrNm">
            <el-select
                v-model="ruleForm.receiverAddrIndex"
                placeholder="请选择详细地址"
                style="width: 100%"
                clearable
                filterable
                @change="handleReceiverAddrChange"
            >
              <el-option
                  v-for="(item, index) in receiverAddrList"
                  :key="item.addrId + '' +  index"
                  :label="item.addrNm"
                  :value="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-row>
        <el-col :span="19"><div style="height: 5px"></div></el-col>
        <el-col :span="5">
          <el-checkbox v-model="ruleForm.autoSaveReceiver" size="small">
            自动保存收货方信息
          </el-checkbox>
        </el-col>
      </el-row> -->

      <!-- 货物信息 -->
      <div class="header-line">
        <h2 class="header-title">货物信息</h2>
      </div>
      <template v-for="(item, index) in goodsList">
        <!-- <el-form
          :model="item"
          :key="index"
          :rules="rules2"
          :ref="'form' + index"
          label-width="90px"
          size="mini"
        > -->
        <el-row :key="index" class="dialog-row">
          <el-col :span="6">
            <el-form-item label="货名" :prop="'cgNm' + index">
              <el-input
                v-model.trim="ruleForm['cgNm' + index]"
                type="text"
                size="small"
                clearable
                style="width: 100%"
                placeholder="货物名称"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="件数" :prop="'instQty' + index" label-width="60px">
              <!-- <el-input
                v-model.lazy="ruleForm['instQty' + index]"
                type="text"
                size="small"
                clearable
                style="width: 100%"
                placeholder="件数"
                @input="handleNumber"
              >
                <span slot="suffix">件</span>
              </el-input> -->
              <div class="lc-flex">
                <el-input-number v-model="ruleForm['instQty' + index]" :step="1" :min="0" step-strictly></el-input-number><span>件</span>
              </div>
              
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="重量" :prop="'instWei' + index" label-width="60px">
              <!-- <el-input
                v-model.number="ruleForm['instWei' + index]"
                type="text"
                size="small"
                clearable
                style="width: 100%"
                placeholder="重量"
              >
                <span slot="suffix">kg</span>
              </el-input> -->
              <div class="lc-flex">
                <el-input-number v-model="ruleForm['instWei' + index]" :step="1" :min="0"></el-input-number><span>吨</span>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="体积" :prop="'instVol' + index" label-width="60px">
              <!-- <el-input
                v-model.number="ruleForm['instVol' + index]"
                type="text"
                size="small"
                clearable
                style="width: 100%"
                placeholder="体积"
              >
                <span slot="suffix">m³</span>
              </el-input> -->
              <div class="lc-flex">
                <el-input-number v-model="ruleForm['instVol' + index]" :step="1" :min="0"></el-input-number><span>m³</span>
              </div>
            </el-form-item>
          </el-col>
          <template v-if="type === 'add'">
            <el-col v-if="index === 0" :span="1" class="row_table_col1 add-btn">
              <i class="el-icon-circle-plus" @click="addContactRow()" />
            </el-col>
            <el-col v-else :span="1" class="row_table_col1 delete-btn">
              <i class="el-icon-remove" @click="removeContactRow(index)" />
            </el-col>
          </template>
          
        </el-row>
        <!-- </el-form> -->
      </template>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="ruleForm.remark"
          type="textarea"
          size="small"
          :autosize="{ minRows: 2}"
          clearable
          style="width: 100%"
          placeholder="请输入内容"
        ></el-input>
      </el-form-item>
      <div v-if="!disabled" class="lc-tac">
        <el-button type="primary" size="mini" @click="handleSubmit">提交订单</el-button>
      </div>
      <a-map
        v-if="!disabled"
        ref="amap"
        :visible.sync="visible"
        :isEdit="isAddressEdit"
        :confirm="confirmDetailAddr"
        :appendToBody="appendToBody"
        @confirm="confirmCallBack"
      ></a-map>
    </el-form>
    
</template>

<script>
import dayjs from 'dayjs'
import { regionData } from 'element-china-area-data'
import AMap from '@/components/DetailAMap/index.vue'
import { orderTypeEnum, carTypeEnum } from '@/constant/enum.js'
import {
  getConsignerAddrs,
  getConsignerContacts,
  getBaseReceiverList,
  getBaseReceiverContactList,
  getBaseReceiverAddrList,
  addPlanBill,
  updatePlanBill,
  getPlanBill
} from '@/api/order.js'
export default {
  components: {
    AMap
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    type: { // 类型 add: 新增下单，edit：编辑，detail：详情
      type: String,
      default: 'add'
    },
  },
  data() {
    const self = this
    return {
      planBillId: '',// 订单号
      visible: false, // 发货人地图
      detailType: 1, // 1：发货人地址，2是收货人地址
      span: 12,
      ruleForm: {
        deliveryDt: '', // 发货日期
        expectDt: '', // 到货日期
        transTp: '', // 订单类型
        carTp: '', // 车辆类型
        cnorCtcts: '', // 发货姓名
        cnorTel: '', // 发货人手机
        cnorAeCode: '', // 发货人城市
        cnorAeName: '', // 发货人城市
        cnorAddr: '', // 发货人详细地址
        cnorAddrId: '',// 发货人地址Id
        cnorAddrNm: '',
        cnorLongitude: 0,
        cnorLatitude: 0,
        cnorAddrIndex: null, // 发货人地址Index,用于下单传参

        rcvrId: '', // 收货单位的Id
        rcvrNm: '', // 收货单位

        rcvrContactId: '', // 收货人的id
        rcvrCtcts: '', // 收货人
        rcvrTel: '', // 收货人手机号
        receiverCity: '',
        addrNm: null, // 收货人详细地址
        addrId: '',
        aeCode: '', // 收货城市编码
        aeName: '',
        receiverLongitude: 0,
        receiverLatitude: 0,
        receiverAddrIndex: '', // 收货地址Index,用于下单传参

        autoSaveReceiver: false,
        remark: '',

        cnorCtctsIndex: null, // 发货人列表的index
        cnorAddrIndex: null, // 发货地址列表的index
        rcvrNmIndex: null, // 收货单位的index
        receiverAddrIndex: null, // 收货人地址列表的index
        rcvrContactIndex: null, // 收货人列表的index

        cgNm0: null,
        instQty0: 0,
        instWei0: 0,
        instVol0: 0
      },
      // goodsList只用于展示计算
      goodsList: [
        {
          cgNm: '',
          instQty: '',
          instWei: '',
          instVol: ''
        }
      ],
      rules: {
        
        deliveryDt: [
          { required: true, message: '请选择发货日期', trigger: 'change' }
        ],
        expectDt: [
          { required: true, message: '请选择到货日期', trigger: 'change' }
        ],
        transTp: [
          { required: true, message: '请选择订单类型', trigger: 'change' }
        ],
        carTp: [
          { required: true, message: '请选择车型', trigger: 'change' }
        ],
        // 发货
        cnorCtcts: [
          { required: true, message: '请选择发货人姓名', trigger: 'change' }
        ],
        cnorCtctsIndex: [
          { required: true, message: '请选择发货人姓名', trigger: 'change' }
        ],
        cnorTel: [
          { required: true, message: '请选择发货人手机号', trigger: 'change' }
        ],
        cnorAddrNm: [
          { required: true, message: '请选择发货地址', trigger: 'change' }
        ],
        cnorAddrIndex: [
          { required: true, message: '请选择发货地址', trigger: 'change' }
        ],
        cnorAddr: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
        // 收货
        rcvrNm: [
          { required: true, message: '请选择收货单位', trigger: 'change' }
        ],
        rcvrNmIndex: [
          { required: true, message: '请选择收货单位', trigger: 'change' }
        ],
        rcvrCtcts: [
          { required: true, message: '请选择收货人姓名', trigger: 'change' }
        ],
        rcvrContactIndex: [
          { required: true, message: '请选择收货人姓名', trigger: 'change' }
        ],
        rcvrTel: [
          { required: true, message: '请选择收货人手机号', trigger: 'change' }
        ],
        receiverAddrIndex: [
          { required: true, message: '请选择收货城市', trigger: 'change' }
        ],
        addrNm: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
        
      },
      rules2: {
        cgNm: [
          { required: true, message: '请输入货物名称', trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        instQty: [
          { required: true, message: '请输入件数', trigger: 'blur' },
        ],
        instVol: [
          { required: true, message: '请输入体积', trigger: 'blur' },
        ],
        instWei: [
          { required: true, message: '请输入重量', trigger: 'blur' },
        ],
      },
      // 订单类型枚举
      orderTypeEnum: orderTypeEnum,
      carTypeEnum: carTypeEnum,
      // 中国城市列表 省市区
      regionData: regionData,
      cnorAddrList: [], // 发货地址列表
      consignerContactList: [], // 发货人和电话
      receiverList: [], // 收货方单位列表
      receiverContactList: [], // 收货方联系人和手机号列表
      receiverAddrList: [], // 收货地址列表
      disableExpectDt(time) { // 到货日期
        if(self?.ruleForm?.deliveryDt) {
          return time.getTime() < new Date(dayjs(self?.ruleForm?.deliveryDt || new Date())).getTime()
        } else {
          return false
        }
      },
      disableDeliveryDt(time) { // 发货日期
        if(self?.ruleForm?.expectDt) {
          return time.getTime() > new Date(dayjs(self?.ruleForm?.expectDt || new Date())).getTime()
        } else {
          return false
        }
      },
      appendToBody: false, // fix: 地图被modal遮住的问题
      isAddressEdit: false, // 回显地图地址
      
    }
  },
  computed: {
    
  },
  watch: {
    goodsList: {
      handler(newValue) {
        newValue.forEach((item, index) => {
          this.rules['cgNm' + index] = [{ required: true, message: '请输入货物名称', trigger: 'blur' }]
          this.rules['instQty' + index] = [{ required: true, message: '请输入件数', trigger: 'blur' }]
          this.rules['instWei' + index] = [{ required: true, message: '请输入体积', trigger: 'blur' }]
          this.rules['instVol' + index] = [{ required: true, message: '请输入重量', trigger: 'blur' }]
        })
      },
      immediate: true,
      // deep: true
    }
  },
  created() {
    this.getConsignerAddrList()
    this.getConsignerContactList()
    this.getBaseReceiverList()
  },
  methods: {
    // 提交订单
    async handleSubmit() {
      try {
        await this.$refs['ruleForm'].validate()

        this.ruleForm.cnorCtcts = this.consignerContactList[this.ruleForm.cnorCtctsIndex].cnorCtcts
        this.ruleForm.cnorTel = this.consignerContactList[this.ruleForm.cnorCtctsIndex].cnorTel
        this.ruleForm.cnorTel = this.consignerContactList[this.ruleForm.cnorCtctsIndex].cnorTel
        this.ruleForm.cnorCtcts = this.consignerContactList[this.ruleForm.cnorCtctsIndex].cnorCtcts
        this.ruleForm.cnorContactId = this.consignerContactList[this.ruleForm.cnorCtctsIndex].cnorContactId

        this.ruleForm.cnorAddrNm = this.cnorAddrList[this.ruleForm.cnorAddrIndex].cnorAddrNm
        this.ruleForm.cnorAddrId = this.cnorAddrList[this.ruleForm.cnorAddrIndex].cnorAddrId
        this.ruleForm.cnorAeCode = this.cnorAddrList[this.ruleForm.cnorAddrIndex].aeCode
        this.ruleForm.cnorAeName = this.cnorAddrList[this.ruleForm.cnorAddrIndex].aeName
        this.ruleForm.cnorLatitude = this.cnorAddrList[this.ruleForm.cnorAddrIndex].lat
        this.ruleForm.cnorLongitude = this.cnorAddrList[this.ruleForm.cnorAddrIndex].lng

        this.ruleForm.rcvrNm = this.receiverList[this.ruleForm.rcvrNmIndex].rcvrNm
        this.ruleForm.rcvrId = this.receiverList[this.ruleForm.rcvrNmIndex].rcvrId

        this.ruleForm.rcvrCtcts = this.receiverContactList[this.ruleForm.rcvrContactIndex].rcvrCtcts
        this.ruleForm.rcvrTel = this.receiverContactList[this.ruleForm.rcvrContactIndex].rcvrTel
        this.ruleForm.rcvrContactId = this.receiverContactList[this.ruleForm.rcvrContactIndex].rcvrContactId
        this.ruleForm.rcvrTel = this.receiverContactList[this.ruleForm.rcvrContactIndex].rcvrTel
        this.ruleForm.rcvrCtcts = this.receiverContactList[this.ruleForm.rcvrContactIndex].rcvrCtcts

        this.ruleForm.aeName = this.receiverAddrList[this.ruleForm.receiverAddrIndex].aeName
        this.ruleForm.addrId = this.receiverAddrList[this.ruleForm.receiverAddrIndex].addrId
        this.ruleForm.addrNm = this.receiverAddrList[this.ruleForm.receiverAddrIndex].addrNm
        this.ruleForm.receiverLatitude = this.receiverAddrList[this.ruleForm.receiverAddrIndex].lat
        this.ruleForm.receiverLongitude = this.receiverAddrList[this.ruleForm.receiverAddrIndex].lng
        this.ruleForm.aeCode = this.receiverAddrList[this.ruleForm.receiverAddrIndex].aeCode
        const {
          deliveryDt,
          expectDt,
          transTp,
          carTp,
          cnorContactId,
          cnorCtcts,
          cnorTel,
          cnorAddrId,
          cnorAeCode,
          cnorAeName,
          cnorLongitude,
          cnorLatitude,
          cnorAddrNm,
          rcvrId,
          rcvrNm,
          rcvrContactId,
          rcvrCtcts,
          rcvrTel,
          addrId,
          addrNm,
          aeCode,
          aeName,
          receiverLongitude,
          receiverLatitude,
          autoSaveReceiver,
          remark
        } = this.ruleForm
        //TODO 需要重整数据
        let params = {
          deliveryDt,
          expectDt,
          transTp,
          carTp,
          consigner: {
            cnorContactId,
            cnorCtcts,
            cnorTel,
          },
          consignerAddr: {
            cnorAddrId,
            aeCode: cnorAeCode,
            aeName: cnorAeName,
            lng: cnorLongitude,
            lat: cnorLatitude,
            cnorAddrNm,
          },
          receiver: {
            rcvrId,
            rcvrNm,
          },
          receiverContact: {
            rcvrContactId,
            rcvrCtcts,
            rcvrTel,
          },
          receiverAddr: {
            addrId,
            addrNm,
            aeCode,
            aeName,
            lng: receiverLongitude,
            lat: receiverLatitude,
          },
          autoSaveReceiver,
          remark
        }
        const planBillDetails = this.goodsList.map((item, index) => {
          return {
            cgNm: this.ruleForm['cgNm' + index],
            instQty: this.ruleForm['instQty' + index],
            instWei: this.ruleForm['instWei' + index],
            instVol: this.ruleForm['instVol' + index],
          }
        })
        params.planBillDetails = planBillDetails

        if(this.type === 'add') {
          const res = await addPlanBill(params)
          // console.log(res)
          if(res.data) {
            this.$message.success('下单成功')
            // this.$store.commit('navBar/DEL_CACHED_VIEW', {
            //   name: 'OrderManagement'
            // })
            // await this.$nextTick()
            this.$router.push({
              path: '/order/order-management',
              query: {
                getData: 1
              }
            })
          }
        } else if(this.type === 'edit') {
          params.planBillId = this.planBillId
          const res = await updatePlanBill(params)
          // console.log(res)
          // if(res.data) {
            this.$message.success('订单修改成功')
            this.$emit('update:visible', false)
            this.$emit('getTableData')
          // }
        }
        
      } catch (error) {
        console.log(error)
        return false
      }

    },
    // 获取订单详情
    async getOrderDetail(id) {
      this.planBillId = id
      try {
        const res = await getPlanBill({
          planBillId: id
        })
        
        const {
          deliveryDt,
          expectDt,
          transTp,
          carTp,
          remark,
        } = res.data
        const {
          cnorContactId,
          cnorCtcts,
          cnorTel
        } = res.data.consigner // 收货人和电话
        const {
          cnorAddrId,
          cnorAddrNm,
          // cnorAeCode: aeCode,
          // cnorAeName: aeName,
          // cnorLongitude: lng,
          // cnorLatitude: lat,
        } = res.data.consignerAddr // 收货地址
        const {
          rcvrId,
          rcvrNm
        } = res.data.receiver // 收货单位
        const {
          rcvrContactId,
          rcvrCtcts,
          rcvrTel
        } = res.data.receiverContact // 收货人和手机号

        const {
          addrId,
          addrNm,
          aeCode,
          aeName,
          lat,
          lng
        } = res.data.receiverAddr // 收货地址


        // 发货联系人
        if(this.consignerContactList.length > 0 && this.ruleForm.cnorCtctsIndex === null) {
          const cnorCtctsIndex = this.consignerContactList.findIndex(v => v.cnorContactId === cnorContactId)
          this.ruleForm.cnorCtctsIndex = cnorCtctsIndex > -1 ? cnorCtctsIndex : null
        }
        // 发货地址
        if(this.cnorAddrList.length > 0 && this.ruleForm.cnorAddrIndex === null) {
          const cnorAddrIndex = this.cnorAddrList.findIndex(v => v.cnorAddrId === cnorAddrId)
          this.ruleForm.cnorAddrIndex = cnorAddrIndex > -1 ? cnorAddrIndex : null
        }
        // 收货单位
        if(this.receiverList.length > 0 && this.ruleForm.rcvrNmIndex === null) {
          const rcvrNmIndex = this.receiverList.findIndex(v => v.rcvrId === rcvrId)
          this.ruleForm.rcvrNmIndex = rcvrNmIndex > -1 ? rcvrNmIndex : null
        }
        // 收货人
        if(this.receiverContactList.length > 0 && this.ruleForm.rcvrContactIndex === null) {
          const rcvrContactIndex = this.receiverContactList.findIndex(v => v.rcvrContactId === rcvrContactId)
          this.ruleForm.rcvrContactIndex = rcvrContactIndex > -1 ? rcvrContactIndex : null
        }
        // 收货地址
        if(this.receiverAddrList.length > 0 && this.ruleForm.receiverAddrIndex === null) {
          const receiverAddrIndex = this.receiverAddrList.findIndex(v => v.addrId === addrId)
          this.ruleForm.receiverAddrIndex = receiverAddrIndex > -1 ? receiverAddrIndex : null
        }
        

        this.ruleForm = Object.assign(this.ruleForm, {
          deliveryDt,
          expectDt,
          transTp,
          carTp,
          cnorContactId,
          cnorCtcts,
          cnorTel,
          cnorAddrId,
          cnorAeCode: res.data.consignerAddr.aeCode,
          cnorAeName: res.data.consignerAddr.aeName,
          cnorLongitude: res.data.consignerAddr.lng,
          cnorLatitude: res.data.consignerAddr.lat,
          cnorAddrNm,
          rcvrId,
          rcvrNm,
          rcvrContactId,
          rcvrCtcts,
          rcvrTel,
          addrId,
          addrNm,
          aeCode,
          aeName,
          receiverLongitude: res.data.receiverAddr.lng,
          receiverLatitude: res.data.receiverAddr.lat,
          // autoSaveReceiver,
          remark
        })
        this.getBaseReceiverContactList()
        this.getBaseReceiverAddrList()
        
        this.goodsList = res.data.planBillDetails
        this.goodsList.forEach((item, index) => {
          this.ruleForm['cgNm' + index] = item.cgNm
          this.ruleForm['instQty' + index] = item.instQty
          this.ruleForm['instWei' + index] = item.instWei
          this.ruleForm['instVol' + index] = item.instVol
        })
        // this.isAddressEdit = true // 地图回显地址
        // this.$nextTick(() => {
        //   this.$refs.amap.form.longitude = res.data.receiverAddr.lng
        //   this.$refs.amap.form.latitude = res.data.receiverAddr.lat
        // })
        
        // console.log(res)
      } catch (error) {
        console.log(error)
      }
    },
    // 重置表单
    async resetForm() {
      await this.$nextTick()
      this.$refs['ruleForm'].resetFields();
    },
    async showMap(id) {
      this.detailType = id
      this.visible = true
      // await this.$nextTick()
      this.$refs.amap.showMap()
    },
    // 地址确认
    confirmDetailAddr() {
    },
    // 发货人确认回调
    confirmCallBack(form) {
      console.log(form)
      // if (this.detailType === 1) {
      //   this.ruleForm.cnorAddr = form.address
      //   this.ruleForm.cnorLongitude = form.longitude
      //   this.ruleForm.cnorLatitude = form.latitude
      //   return
      // }
      this.ruleForm.addrNm = form.address
      this.ruleForm.adCode = form.adCode
      this.ruleForm.receiverLongitude = form.longitude
      this.ruleForm.receiverLatitude = form.latitude
    },
    updateCnorAddr() {},
    addContactRow() {
      this.goodsList.push(
        {
          cgNm: '',
          instQty: '',
          instWei: '',
          instVol: ''
        }
      )
    },
    removeContactRow(index) {
      this.goodsList.splice(index, 1)
    },
    // 获取发货地址
    async getConsignerAddrList() {
      try {
        const res = await getConsignerAddrs()
        this.cnorAddrList = res.data.items
        // console.log(res)
        if(this.type !== 'add' && this.ruleForm.cnorAddrIndex === null) { // 详情和编辑 初始化
          const cnorAddrIndex = this.cnorAddrList.findIndex(v => v.cnorAddrId === this.ruleForm.cnorAddrId)
          this.ruleForm.cnorAddrIndex = cnorAddrIndex > -1 ? cnorAddrIndex : null
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 获取发货联系人和电话
    async getConsignerContactList() {
      try {
        const res = await getConsignerContacts()
        this.consignerContactList = res.data.items
        // console.log(res)
        if(this.type !== 'add' && this.ruleForm.cnorCtctsIndex === null) { // 详情和编辑 初始化
          const cnorCtctsIndex = this.consignerContactList.findIndex(v => v.cnorContactId === this.ruleForm.cnorContactId)
          this.ruleForm.cnorCtctsIndex = cnorCtctsIndex > -1 ? cnorCtctsIndex : null
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 获取收货单位列表
    async getBaseReceiverList() {
      try {
        const res = await getBaseReceiverList({ pageNum: 1, pageSize: 9999 })
        this.receiverList = res.data.items
        // console.log(res)
        if(this.type !== 'add' && this.ruleForm.rcvrNmIndex === null) { // 详情和编辑 初始化
          const rcvrNmIndex = this.receiverList.findIndex(v => v.rcvrId === this.ruleForm.rcvrId)
          this.ruleForm.rcvrNmIndex = rcvrNmIndex > -1 ? rcvrNmIndex : null
          this.getBaseReceiverAddrList()
          this.getBaseReceiverContactList()
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 获取收货联系人
    async getBaseReceiverContactList() {
      if(!this.ruleForm.rcvrNmIndex && this.ruleForm.rcvrNmIndex !== 0) {
        this.receiverContactList = []
        return
      }
      try {
        const res = await getBaseReceiverContactList({ rcvrId: this.ruleForm.rcvrId })
        this.receiverContactList = res.data.items
        // console.log(res)
        if(this.type !== 'add' && this.ruleForm.rcvrContactIndex === null) { // 详情和编辑 初始化
          const rcvrContactIndex = this.receiverContactList.findIndex(v => v.rcvrContactId === this.ruleForm.rcvrContactId)
          this.ruleForm.rcvrContactIndex = rcvrContactIndex > -1 ? rcvrContactIndex : null
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 获取收货地址列表
    async getBaseReceiverAddrList() {
      if(!this.ruleForm.rcvrNmIndex && this.ruleForm.rcvrNmIndex !== 0) {
        this.receiverAddrList = []
        return
      }
      try {
        const res = await getBaseReceiverAddrList({ rcvrId: this.ruleForm.rcvrId })
        this.receiverAddrList = res.data.items
        // console.log(res)
        if(this.type !== 'add' && this.ruleForm.receiverAddrIndex === null) { // 详情和编辑 初始化
          const receiverAddrIndex = this.receiverAddrList.findIndex(v => v.addrId === this.ruleForm.addrId)
          this.ruleForm.receiverAddrIndex = receiverAddrIndex > -1 ? receiverAddrIndex : null
        }
      } catch (error) {
        console.log(error)
      }
    },

    // 发货人选择
    handleCnorCtctsChange(index) {
      if(index || index === 0) {
        this.ruleForm.cnorCtctsIndex = index
        // this.ruleForm.cnorCtcts = this.consignerContactList[index].cnorCtcts
        // this.ruleForm.cnorTel = this.consignerContactList[index].cnorTel
        this.ruleForm.cnorContactId = this.consignerContactList[index].cnorContactId
      } else {
        this.cnorCtctsIndex = null
        // this.ruleForm.cnorCtcts = ''
        // this.ruleForm.cnorTel = ''
        this.ruleForm.cnorContactId = ''
      }
    },
    // 发货人手机号选择
    handleCnorTelChange(index) {
      if(index || index === 0) {
        this.ruleForm.cnorCtctsIndex = index
        // this.ruleForm.cnorTel = this.consignerContactList[index].cnorTel
        // this.ruleForm.cnorCtcts = this.consignerContactList[index].cnorCtcts
        this.ruleForm.cnorContactId = this.consignerContactList[index].cnorContactId
      } else {
        this.ruleForm.cnorCtctsIndex = null
        // this.ruleForm.cnorCtcts = ''
        // this.ruleForm.cnorTel = ''
        this.ruleForm.cnorContactId = ''
      }
    },
    // 发货地址选择
    handleCnorAddr(index) {
      if(index || index === 0) {
        this.ruleForm.cnorAddrIndex = index
        // this.ruleForm.cnorAddrNm = this.cnorAddrList[index].cnorAddrNm
        this.ruleForm.cnorAddrId = this.cnorAddrList[index].cnorAddrId
        // this.ruleForm.cnorAeCode = this.cnorAddrList[index].aeCode
        // this.ruleForm.cnorAeName = this.cnorAddrList[index].aeName
        // this.ruleForm.cnorLatitude = this.cnorAddrList[index].lat
        // this.ruleForm.cnorLongitude = this.cnorAddrList[index].lng
      } else {
        this.ruleForm.cnorAddrIndex = null
        // this.ruleForm.cnorAddrNm = ''
        this.ruleForm.cnorAddrId = null
        // this.ruleForm.cnorAeCode = ''
        // this.ruleForm.cnorAeName = ''
        // this.ruleForm.cnorLatitude = ''
        // this.ruleForm.cnorLongitude = ''
      }
    },
    // 收货单位选择
    handleRcvrNmChange(index) {
      if(index || index === 0) {
        this.ruleForm.rcvrNmIndex = index
        // this.ruleForm.rcvrNm = this.receiverList[index].rcvrNm
        this.ruleForm.rcvrId = this.receiverList[index].rcvrId

        // this.ruleForm.rcvrCtcts = ''
        // this.ruleForm.rcvrTel = ''
        this.ruleForm.rcvrContactId = ''

        // this.ruleForm.aeName = ''
        this.ruleForm.addrId = ''
        this.ruleForm.addrNm = ''
        // this.ruleForm.receiverLongitude = ''
        // this.ruleForm.receiverLatitude = ''
        // this.ruleForm.aeCode = ''
        this.ruleForm.receiverAddrIndex = null
        this.ruleForm.rcvrContactIndex = null
        

        this.getBaseReceiverContactList()
        this.getBaseReceiverAddrList()
      } else {
        this.ruleForm.rcvrNmIndex = null
        // this.ruleForm.rcvrNm = ''
        // this.ruleForm.rcvrId = ''

        // this.ruleForm.rcvrCtcts = ''
        // this.ruleForm.rcvrTel = ''
        this.ruleForm.rcvrContactId = ''

        // this.ruleForm.aeName = ''
        this.ruleForm.addrId = ''
        this.ruleForm.addrNm = ''
        // this.ruleForm.receiverLongitude = ''
        // this.ruleForm.receiverLatitude = ''
        // this.ruleForm.aeCode = ''
        this.ruleForm.receiverAddrIndex = null
        this.ruleForm.rcvrContactIndex = null
        this.receiverContactList = []// 收货方联系人和手机号列表
        this.receiverAddrList = [] // 收货地址列表
      }
    },
    // 收货人选择
    handleRcvrCtctsChange(index) {
      if(index || index === 0) {
        this.ruleForm.rcvrContactIndex = index
        // this.ruleForm.rcvrCtcts = this.receiverContactList[index].rcvrCtcts
        // this.ruleForm.rcvrTel = this.receiverContactList[index].rcvrTel
        // this.ruleForm.rcvrContactId = this.receiverContactList[index].rcvrContactId
      } else {
        this.ruleForm.rcvrContactIndex = null
        // this.ruleForm.rcvrCtcts = ''
        // this.ruleForm.rcvrTel = ''
        // this.ruleForm.rcvrContactId = ''
      }
    },
    // 收货人手机号选择
    handleRcvrTelChange(index) {
      if(index || index === 0) {
        this.ruleForm.rcvrContactIndex = index
        // this.ruleForm.rcvrTel = this.receiverContactList[index].rcvrTel
        // this.ruleForm.rcvrCtcts = this.receiverContactList[index].rcvrCtcts
        // this.ruleForm.rcvrContactId = this.receiverContactList[index].rcvrContactId
      } else {
        this.ruleForm.rcvrContactIndex = null
        // this.ruleForm.rcvrCtcts = ''
        // this.ruleForm.rcvrTel = ''
        // this.ruleForm.rcvrContactId = ''
      }
    },
    // 收货城市选择
    handleReceiverAddrChange(index) {
      if(index || index === 0) {
        this.ruleForm.receiverAddrIndex = index
        // this.ruleForm.aeName = this.receiverAddrList[index].aeName
        // this.ruleForm.addrId = this.receiverAddrList[index].addrId
        this.ruleForm.addrNm = this.receiverAddrList[index].addrNm
        // this.ruleForm.receiverLatitude = this.receiverAddrList[index].lat
        // this.ruleForm.receiverLongitude = this.receiverAddrList[index].lng
        // this.ruleForm.aeCode = this.receiverAddrList[index].aeCode
        this.$refs.amap.form.longitude = this.receiverAddrList[index].lng
        this.$refs.amap.form.latitude = this.receiverAddrList[index].lat
        this.isAddressEdit = true
        
      } else {
        this.ruleForm.receiverAddrIndex = null
        // this.ruleForm.aeName = ''
        // this.ruleForm.addrId = ''
        this.ruleForm.addrNm = null
        // this.ruleForm.receiverLongitude = ''
        // this.ruleForm.receiverLatitude = ''
        // this.ruleForm.aeCode = ''
        this.isAddressEdit = false
        
      }
    },
    
    
  }
}
</script>

<style scoped lang="scss">
.detail-form {
  padding: 0 20px;
}
.header-line {
  position: relative;
  width: 100%;
  height: 2px;
  background-color: $primary-color;
  margin: 30px 0;
  .header-title {
    width: 100px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: $primary-color;
    font-size: 18px;
    position:absolute;
    top: -32px;
    left: 80px;
    background-color: #fff;
  }
}
.tip-container {
  position: relative;
}
.tip {
  position: absolute;
  right: -20px;
  top: 7px;
}
.row_table_col1 {
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: $primary-color;
}
.add-btn {
  position: absolute;
  right: -28px;
  // bottom: -40px;
}
.delete-btn {
  position: absolute;
  right: -28px;
  color: #ff6159;
}


</style>